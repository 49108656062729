import './mobilemenu.scss';
import './mmenu-9.3.0/mmenu.min.css';

import * as dompack from 'dompack';

import $ from 'jquery';
require('./mmenu-9.3.0/mmenu.min.js');

dompack.onDomReady(() => {
  let settings = {
    "offCanvas": {
      "position": "right"
   },
    "navbar": {
      title: " "
    },
  };

  let $menu = new Mmenu( "#my-menu", settings );

  let $icon = $("#toggle-mobile-menu");
  const api = $menu.API;


  $('#toggle-mobile-menu').click(function() {
    api.open();
  });

});
