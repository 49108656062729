import './themes.scss';

import * as dompack from 'dompack';

import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

dompack.onDomReady(() => {
  let elThemesGrid = dompack.qS('#themes-grid');
  if (!elThemesGrid)
    return;

  let masonrySettings = {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    transitionDuration: 500,
    gutter: 0,
    horizontalOrder: true,
  };

  let grid = new Masonry(elThemesGrid, masonrySettings);

  imagesLoaded(elThemesGrid, () => {
    grid.layout();
  });
});
