import * as dompack from 'dompack';
import * as pricecalculation from '../internal/pricecalculation';
import * as finmath from '@mod-system/js/util/finmath';
import { getTid } from "@mod-tollium/js/gettid";

export class ProductTypeHandler {
  constructor(node, product, initialhashparams) {
    this.node = node;
    this.product = product;
    this.initialhashparams = initialhashparams;

    //we need indirections to be able to invoke user-overriden interfaces TODO but we could also use them to validate the user's implementations!
    this.product.oncalculateprice = (baseprice, hashparams, amount) => this._onCalculatePrice(baseprice, hashparams, amount);
  }

  calculatePrice(baseprice, hashparams, amount) {
    return baseprice;
  }

  _onCalculatePrice(baseprice, hashparams, amount) {
    const result = this.calculatePrice(baseprice, hashparams, amount);
    if (result !== "onrequest" && !finmath.isValidPrice(result))
      throw new Error(`The oveerride for calculatePrice returned an invalid price '${result}'`);
    return result;
  }
}

export default class Product
{
  constructor(webshop,node)
  {
    this.webshop = webshop;
    this.node = node;
    this._currentoptions = [];
    this._skippriceupdates = true; // no price updates (and hash rewrites) during init
    this._updating_selects = false;
    this._initialized = false;

    this.onselectorchange = null;
    this.oncalculateprice = null;
    this.oncalculateconfiguration = null;
    this.oncheckbeforeadd = null;

    if(!this.node.dataset.webshopProduct)
    {
      console.error("missing dataset.webshopProduct, did you add [product.formattributes]?", node);
      return;
    }

    this.productinfo = JSON.parse(this.node.dataset.webshopProduct);
    this.dynpriceinfo = JSON.parse(this.node.dataset.webshopDynpriceinfo);

    dompack.qSA(this.node, ".webshop-product__add").forEach(
      addbutton => addbutton.addEventListener("click", event => this._doAdd(event)));
    dompack.qSA(this.node, ".webshop-product__optionselect").forEach(
      optionselect => optionselect.addEventListener("change", event => this._onSelectorChange(event)));
    dompack.qSA(this.node, "[name=amount]").forEach(
      amountinput => amountinput.addEventListener("input", event => this._updatePrice()));

    window.addEventListener("webshop:cartupdated", event => this._cartUpdated());
    this._initializedpromise = this.init();
  }

  get id()
  {
    return this.productinfo.id;
  }

  getProductTitle()
  {
    return this.productinfo.title;
  }

  getProductValuesFromHash(hashparams)
  {
    const productvalues = [];
    for (const opt of this.productinfo.fixedoptions)
      productvalues.push(opt.productvalue);

    for(let select of dompack.qSA(this.node, ".webshop-product__optionselect"))
    {
      let value = hashparams.get('po_' + select.dataset.productoption);
      if(!value)
        continue;

      let selected = Array.from(select.options).find(opt => opt.dataset.productvalue == value);
      if (selected.value)
        productvalues.push(Number(selected.value));
    }

    return productvalues;
  }

  async init()
  {
    let hashparams = new URLSearchParams((new URL(location.href)).hash.substr(1));

    const productvalues = this.getProductValuesFromHash(hashparams);
    /* We need to be sure init has completed executed before products are added so they
       have a chance to modify product handling. This has raced in the past, and they were more easily reproduced by
       waiting here:
       await new Promise(resolve => setTimeout(resolve,3000));
    */

    await this._updateOptions(
        { productvalues
        },
        { inithandlers_cb: () =>
          {
            // Let the product handlers process the configuration
            this.webshop.options.productpagetypes.forEach(type =>
            {
              if (type.name === (this.productinfo.type || 'defaultproduct'))
                new type.handler(this.node, this, hashparams);
            });
          }
        });

    this._initialized = true;
  }

  _updateAfterSelectionChange({ sethash = false, forcartupdate = false } = {})
  {
    const { fixedoptions, options } = this._gatherCurrentOptions();
    this._updateOptions(
        { productvalues: [ ...fixedoptions, ...options ]
        }, { sethash, forcartupdate });
  }

  async _updateOptions(productoptions, { inithandlers_cb, sethash, forcartupdate = false } = {})
  {
    const lock = dompack.flagUIBusy();
    this._updating_selects = true;
    try
    {
      const res = await this.webshop._getProductEnabledOptions(this.productinfo.id, { ...productoptions, allowselectionoutofstock: forcartupdate });

      let gotchange = false;

      // Process the selection, enabled options and hidden options from the options
      for (const optionrow of dompack.qSA(this.node, ".webshop-product__option"))
      {
        const select = dompack.qS(optionrow, ".webshop-product__optionselect");
        const productoption = Number(select.dataset.productoptionid);

        const sel = res.selection.find(_ => _.productoption === productoption);
        for (const optionnode of select.options)
        {
          const productvalue = Number(optionnode.value)|0;
          if (!productvalue)
            continue;

          const active = sel.candidates.includes(productvalue);

          if (optionnode.disabled != !active)
          {
            // set classes and disable inactive options
            optionnode.disabled = !active;
            optionnode.hidden = !active;
            optionnode.classList.toggle("webshop-product__optionselect--inactive", !active);
            gotchange = true;
          }
        }

        optionrow.classList.toggle("webshop-product__option--hiddendefault", sel.hiddendefault);

        if (select.value != (sel.productvalue || ""))
        {
          dompack.changeValue(select, sel.productvalue || "");
          gotchange = true;
        }

        if (gotchange)
          dompack.dispatchCustomEvent(select, "webshop:optionschanged", { bubbles:true, cancelable:false });
      }

      if (gotchange && this.onselectorchange)
        this.onselectorchange();

      if (inithandlers_cb)
        inithandlers_cb();

      const stockinfo = pricecalculation.constructStockInfoFromStockTiers(res.stocktiers);
      pricecalculation.publicizeStockInfo(this.webshop, this.node, stockinfo, res.stocktiers);

      this._updatePrice(sethash);
    }
    finally
    {
      this._updating_selects = false;
      lock.release();
    }
  }

  _getAmount({ fixillegal = true } = {})
  {
    if (this.productinfo.fixedamount)
      return this.productinfo.fixedamount;

    let amountcontrol = this.node.querySelector("[name=amount]");
    if (!amountcontrol)
    {
      //sometimes 'order amounts' don't make sense in a shop, so assume 1 if the control is missing
      return 1;
    }

    let amount = parseInt(amountcontrol.value);
    if (!(amount > 0)) //NaN-safe compare
    {
      if (fixillegal)
        amount = 1;
      else
        this.webshop.reportStatus(`An invalid order amount was specified (${amountcontrol.value})`);
    }

    return amount;
  }

  getSelectedOptions()
  {
    let currentoptions = [];

    if (this.productinfo.fixedoptions)
    {
      for (let opt of this.productinfo.fixedoptions)
      {
        const optionid = parseInt(opt.productvalue);
        if(!optionid)
          continue; //apparently a nonrequired option, which gets passed as 0. don't add it!

        currentoptions.push({ optionid
                            , label: opt.label
                            , selected: opt.value
                            , fixed: true
                            });
      }
    }

    for(let optionrow of dompack.qSA(this.node, ".webshop-product__option"))
    {
      let optionlabel = dompack.qS(optionrow, ".webshop-product__optionlabel");
      let select = dompack.qS(optionrow, ".webshop-product__optionselect");
      if(!optionlabel || !select)
        throw new Error("Option is not following model webshop-product__option model");

      let selected = select.options[select.selectedIndex];
      if(selected)
      {
        if (!selected.value)
          continue;

        currentoptions.push( { optionid: parseInt(selected.value)
                             , label: optionlabel.textContent
                             , selected: selected.textContent
                             , hiddendefault: optionrow.classList.contains("webshop-product__option--hiddendefault")
                             , fixed: false
                             });
      }
    }

    return currentoptions;
  }

  _gatherCurrentOptions()
  {
    let sourceoptions = this.getSelectedOptions();
    const fixedoptions = sourceoptions.filter(e => e.fixed).map(e => e.optionid);
    const options = sourceoptions.map(e => e.optionid);
    return { fixedoptions, options };
  }

  _cartUpdated()
  {
    this._updateAfterSelectionChange({ forcartupdate: true });
  }

  async _updatePrice(sethash)
  {
    if(!this._initialized) //we only await if needed, helps tests who update options/amounts to not have to await themselves
      await this._initializedpromise;

    const amount = this._getAmount();

    const { fixedoptions, options } = this._gatherCurrentOptions();
    const { hash } = pricecalculation.updateProductPrices(this.webshop, this.node, { amount, options, fixedoptions, oncalculateprice: this.oncalculateprice });
    if (sethash)
    {
      if(hash.toString())
        location.hash = "#" + hash;
      else if(location.hash)
        location.hash = '#';
    }
  }

  async _doAdd(event)
  {
    await this._initializedpromise;

    event.preventDefault();
    event.stopPropagation();

    const amount = this._getAmount({ fixillegal: false });
    if(!(amount >=1 ))
      return;

    if (this.oncheckbeforeadd && !this.oncheckbeforeadd())
      return;

    const lock = dompack.flagUIBusy();

    let configuration;
    if (this.oncalculateconfiguration)
      configuration = this.oncalculateconfiguration();

    let fulloptions = this.getSelectedOptions();
    const options = fulloptions.map(e => e.optionid);
    const priceinfo = pricecalculation.calculateProductPrices(this.webshop, this.node, { amount, options, oncalculateprice: this.oncalculateprice });
    const discounts = pricecalculation.getRelevantDiscountsForProduct(this.webshop, this.productinfo.id);
    const bestthumbnail = pricecalculation.getBestImage(this.productinfo.thumbnails, options);

    const addedproduct = { product:    this.productinfo.id
                         , amount
                         , options:    fulloptions
                         , baseprice:  priceinfo.prediscountprice
                         , priceonrequest: priceinfo.priceonrequest
                         , discount:   priceinfo.price === "onrequest" ? "onrequest" : finmath.subtract(priceinfo.prediscountprice, priceinfo.price)
                         , title:      this.productinfo.title
                         , thumbnail:  bestthumbnail
                         , discounts
                         , configuration
                         , brand:      this.productinfo.brand
                         , categorypath: this.productinfo.categorypath
                         , sku:        this.productinfo.sku
                         };

    //add to cart (ADDME send a RPC to confirm/update)
    const { lineuid, addedamount, totalamount } = await this.webshop._addToCart(addedproduct, { mergeidenticalconfigs: this.productinfo.cartmergeidenticalconfigs });
    addedproduct.amount = addedamount;
    addedproduct.triedaddamount = amount;
    addedproduct.totalamount = totalamount;

    //reset the ordered amount if present
    const amountcontrol = this.node.querySelector("[name=amount]");
    if (amountcontrol)
      amountcontrol.value = 1;

    let hascrosssell = Boolean(this.node.dataset.webshopHascrosssell);

    if(dompack.dispatchCustomEvent(event.target, "webshop:productadded", { bubbles:true, cancelable:true, detail: { ...addedproduct, webshop: this.webshop, productnode: this.node, lineuid, hascrosssell } }))
    {
      if (addedproduct.amount === 0)
        this.webshop.reportStatus(getTid("webshop:frontend.checkout.nomoreselectedproductinstock"));
      else
      {
        if (addedproduct.amount !== addedproduct.triedaddamount)
          await this.webshop.reportStatus(getTid("webshop:frontend.checkout.selectedproductpartialamountinstock", addedproduct.amount));
        if (hascrosssell)
        {
          window.setTimeout(() =>
          {
            // FIXME: make this a webshop function
            location.href = location.href = this.webshop.options.catalogroot + `webshop/combine?p=${this.productinfo.id}&o=${options.join(",")}&i=${lineuid}&a=${addedamount}`;
          }, 100); //give GTM time to pick up the product add  (TODO fix this in the main code. work with GTA to get supporting browsers into BEACON mode)
        }
        else
          window.setTimeout(() => this.webshop.gotoCheckout(), 100); //give GTM time to pick up the product add  (TODO fix this in the main code. work with GTA to get supporting browsers into BEACON mode)
        return; // keep the lock until the redirect
      }
    }

    lock.release();
  }

  async _onSelectorChange()
  {
    if(!this._initialized) //we only await if needed, helps tests who update options/amounts to not have to await themselves
      await this._initializedpromise;

    if (this._updating_selects)
      return;

    await this._updateAfterSelectionChange({ sethash: true });

    if (this.onselectorchange)
      this.onselectorchange();
  }

  updatePrice()
  {
    if (!this._updating_selects)
      this._updateAfterSelectionChange();
    else
      this._updatePrice(true);
  }
}
