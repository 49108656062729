import * as finmath from '@mod-system/js/util/finmath';

function strcmpi(lhs,rhs)
{
  lhs = lhs.toUpperCase();
  rhs = rhs.toUpperCase();
  return lhs < rhs ? -1 : lhs === rhs ? 0 : 1;
}

function getProductTitle(node)
{
  let titlenode = node.querySelector('.webshop-products__title');
  return titlenode ? titlenode.textContent : '';
}

export default class ProductOrderingControl
{
  constructor(node)
  {
    this.node = node;
    this.ordersymbol = Symbol();

    //store initial ordering
    this.getProductsInScope().forEach( (node,idx) => node[this.ordersymbol] = idx);

    //listen for changes
    this.node.addEventListener('change', evt => this.reorder());
    //just globally listen for price changes and reorder then. we might do more specific integration later
    window.addEventListener("webshop:internal-pricechange", evt => this.reorder());
    this.reorder();
  }

  getProductsInScope()
  {
    let holder = this.node.closest(".webshop-products");
    if(!holder)
      return [];

    return Array.from(holder.querySelectorAll(".webshop-products__item"));
  }

  sortByOrdering(lhs, rhs)
  {
    return lhs[this.ordersymbol] - rhs[this.ordersymbol];
  }

  sortByTitle(lhs, rhs)
  {
    return strcmpi(getProductTitle(lhs), getProductTitle(rhs)) || this.sortByOrdering(lhs, rhs);
  }

  sortByPrice(lhs, rhs)
  {
    return finmath.cmp(lhs.dataset.webshopCurrentprice, rhs.dataset.webshopCurrentprice) || this.sortByTitle(lhs, rhs);
  }

  reorder()
  {
    let requestedorder = this.node.value;
    let reverse;

    if(requestedorder.endsWith("-desc"))
    {
      reverse = true;
      requestedorder = requestedorder.substr(0, requestedorder.length - 5); //strip -desc
    }
    else if(requestedorder.endsWith("-asc"))
    {
      requestedorder = requestedorder.substr(0, requestedorder.length - 4); //strip -asc
    }

    //TODO registry to which external users can add their own sort methods
    let orderingfunction;
    switch(requestedorder)
    {
      case 'ordering':
        orderingfunction = (lhs, rhs) => this.sortByOrdering(lhs,rhs);
        break;

      case 'title':
        orderingfunction = (lhs, rhs) => this.sortByTitle(lhs, rhs);
        break;

      case 'price':
        orderingfunction = (lhs, rhs) => this.sortByPrice(lhs, rhs);
        break;

      default:
        throw new Error(`Ordering function '${requestedorder}' not understood`);
    }

    if(reverse)
    {
      //we can't do: orderingfunction = (lhs,rhs) => orderingfunction(rhs,lhs);
      let x = orderingfunction;
      orderingfunction = (lhs,rhs) => x(rhs,lhs);
    }

    let currentorder = this.getProductsInScope();
    let neworder = [...currentorder].sort(orderingfunction);

    let orderingchange = neworder.some( (el,idx) => currentorder[idx] != el);
    if(orderingchange)
      currentorder[0].after(...neworder);
  }
}
