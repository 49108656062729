import * as whintegration from '@mod-system/js/wh/integration';
import * as gtm from '@mod-publisher/js/analytics/gtm';
import * as dompack from 'dompack';
import * as finmath from '@mod-system/js/util/finmath';

window.__webshop_gtm_loaded = true;

let gtmoptions;
const ecommercebase = { currencyCode: "EUR" //GA3
                      , currency: "EUR" //GA4
                      };

function getFallbackSource()
{
  if(document.documentElement.classList.contains("webshop--isproductpage"))
    return "Product page";
  if(document.documentElement.classList.contains("webshop--iscategorypage"))
    return "Category page";
  if(document.documentElement.classList.contains("webshop--istagpage"))
    return "Tag page";
  return "Unknown list";
}

function productInfoFromEvent(prod)
{
  let options = prod.options.map(opt => opt.selected).filter(opt => !!opt).join(', ');
  let price;
  if(prod.prediscountlinetotal && prod.amount)
    price = (finmath.divide || finmath.moneyDivide)(prod.prediscountlinetotal, prod.amount);
  else
    price = prod.baseprice;

  return { name: prod.title
         , id: prod.sku || prod.product
         , price: price
         , brand: prod.brand
         , category: prod.categorypath?.join('/') ?? ""
         , quantity: prod.amount
         , discounts: prod.discounts
         , variant: options
         };
}

function productInfoFromEvent4(prod)
{
  let options = prod.options.map(opt => opt.selected).filter(opt => !!opt).join(', ');
  return { item_name: prod.title
         , item_id: prod.sku || prod.product
         , price: prod.baseprice
         , discount: prod.discount
         , item_brand: prod.brand
         , item_category: prod.categorypath?.[0] || ""
         , item_category2: prod.categorypath?.[1] || ""
         , item_category3: prod.categorypath?.[2] || ""
         , item_category4: prod.categorypath?.[3] || ""
         , item_category5: prod.categorypath?.[4] || ""
         , quantity: prod.amount
          // , discounts: prod.discounts //GA4 wants a single discount value?
         , item_variant: options
         };
}

function checkDataLayer()
{
  //create impressions per data-webshop-impressionlist
  let counterspersource = {};
  let impressions = [];
  let items = [];
  for(let product of dompack.qSA('*[data-webshop-isimpression]'))
  {
    let source = product.closest('*[data-webshop-impressionlist]');
    let sourcename = source ? source.dataset.webshopImpressionlist : getFallbackSource();
    if(counterspersource[sourcename])
      ++counterspersource[sourcename];
    else
      counterspersource[sourcename]=1;

    //https://developers.google.com/tag-manager/enhanced-ecommerce
    product.webshopImpressionInfo = { ...JSON.parse(product.dataset.webshopImpression), list: sourcename, position: counterspersource[sourcename]};
    //https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#product_views_and_interactions
    if(product.dataset.webshopItem)
      product.webshopImpressionItem = { ...JSON.parse(product.dataset.webshopItem), item_list_id: sourcename, index: counterspersource[sourcename]};

    impressions.push(product.webshopImpressionInfo);
    items.push(product.webshopImpressionItem);

    if(impressions.length >= gtmoptions?.maximpressions) //too avoid too long lists, limit to 25
      break;
  }

  if(impressions.length > 0)
  {
    window.dataLayer.push({ ecommerce: { ...ecommercebase
                                       , impressions: impressions //GA3
                                       , items: items //GA4
                                       }
                          , event: 'view_item_list'
                          });
  }

  if(document.documentElement.classList.contains("webshop--isproductpage"))
  {
    //This is a 'product Detail' page in GTM speak
    let prodnode = document.querySelector("webshop-product[data-webshop-product]");
    if(prodnode) //GA4
    {
      let productinfo;
      if(prodnode.dataset.webshopItem)
        productinfo = JSON.parse(prodnode.dataset.webshopItem);

      gtm.sendEvent("view_item", { ecommerce: { ...ecommercebase
                                              , items: [productinfo]
                                              } });
    }
    if(prodnode) //GA3
    {
      let prod = JSON.parse(prodnode.dataset.webshopProduct);
      let productinfo = { name: prod.title
                        , id: prod.sku || prod.id
                        , price: prod.price
                        , brand: prod.brand
                        , category: prod.categorypath?.join('/') ?? ""
                        };

      window.dataLayer.push({ ecommerce: { detail: { products: [productinfo] }}});
    }
  }
}

function handlePurchaseCompletion()
{
  //purchase (after successfully placing order)
  let purchasejsonstr = sessionStorage.getItem("purchasedata");
  let orderinfo = whintegration.config.obj["webshop:orderinfo"];

  if( purchasejsonstr && orderinfo && orderinfo.placedorder)
  {
    try
    {
      logCompletedPurchase(orderinfo);
    }
    catch(e)
    {
      console.error("logCompletedPurchase failure",e);
    }
  }
}

let lastshippingmethod, lastpaymentmethod;
let lastshippingmethod4, lastpaymentmethod4;

function getOption(name)
{
  return document.querySelector(`input[name="${name}"]:checked`)?.closest('.wh-form__fieldline')?.querySelector('.wh-form__optionlabel')?.textContent;
}

function onCartUpdated(evt)
{
  let cart = evt.detail.webshop.getCart();
  let ga4products = cart.products.map(productInfoFromEvent4);
  if(!evt.target.__webshopAnnouncedCheckout)
  {
    evt.target.__webshopAnnouncedCheckout = true;
    gtm.sendEvent("begin_checkout",
                    { ecommerce: { ...ecommercebase
                                 , items: ga4products
                                 }});
  }

  let currentshippingmethod = getOption("shippingmethod.shippingmethod");
  if(currentshippingmethod != lastshippingmethod)
  {
    lastshippingmethod = currentshippingmethod;
    if(currentshippingmethod)
      sendCheckoutEvent(evt.detail.webshop, 1, currentshippingmethod);
  }
  if(evt.detail.shippingmethod && `${evt.detail.shippingmethod}-${evt.detail.shippingcost}` != lastshippingmethod4)
  {
    lastshippingmethod4 = `${evt.detail.shippingmethod}-${evt.detail.shippingcost}`;
    gtm.sendEvent("add_shipping_info",
                  { ecommerce: { ...ecommercebase
                               , shipping_tier: evt.detail.shippingmethod
                               , value: evt.detail.shippingcost
                               , items: ga4products
                               }});
  }

  let currentpaymentmethod = getOption("paymentmethod.paymentmethod");
  if(currentpaymentmethod != lastpaymentmethod)
  {
    lastpaymentmethod = currentpaymentmethod;
    if(currentpaymentmethod)
      sendCheckoutEvent(evt.detail.webshop, 2, currentpaymentmethod);
  }

  if(evt.detail.paymentmethod && `${evt.detail.paymentmethod}-${evt.detail.paymentcost}` != lastpaymentmethod4)
  {
    lastpaymentmethod4 = `${evt.detail.paymentmethod}-${evt.detail.paymentcost}`;
    gtm.sendEvent("add_payment_info",
                  { ecommerce: { ...ecommercebase
                               , payment_type: evt.detail.paymentmethod
                               , value: evt.detail.paymentcost
                               , items: ga4products
                               }});
  }
}

function sendCheckoutEvent(webshop,step, option)
{
  let products = webshop.getCart().products.map(_ => productInfoFromEvent(_));
  let ecommerce = { ...ecommercebase
                  , 'checkout': { actionField: { step, option}
                                , products
                                }
                  };
  gtm.sendEvent("checkout", {ecommerce});
}

function initGTM()
{
  checkDataLayer();
  handlePurchaseCompletion();

  if(document.documentElement.classList.contains("webshop--ischeckoutpage"))
  {
    lastshippingmethod = getOption("shippingmethod.shippingmethod");
    lastpaymentmethod = getOption("paymentmethod.paymentmethod");
    window.addEventListener("webshop:cartready", evt => onCartUpdated(evt));
    window.addEventListener("webshop:checkoutwidgetupdated", evt => onCartUpdated(evt));
  }
}

function logCompletedPurchase(orderinfo)
{
  let purchasejsonstr = sessionStorage.getItem("purchasedata");
  sessionStorage.removeItem("purchasedata");

  let paymentdata = JSON.parse(purchasejsonstr);
  if( paymentdata )
  {
    let products = [];
    let products4 = paymentdata.finalcart.products.map(productInfoFromEvent4);

    for( let i = 0; i < paymentdata.cart.products.length; ++i )
    {
      let prod = paymentdata.cart.products[i];
      products.push(productInfoFromEvent(prod));
    }

    const eventname = orderinfo.isquote ? "requestquote" : "purchase";

    gtm.sendEvent(eventname,
                  { 'ecommerce':
                    { ...ecommercebase
                    , items: products4
                    , transaction_id: orderinfo.orderid
                    , value: paymentdata.ordertotal
                    , shipping: paymentdata.shippingcosts
                    , coupon: paymentdata.cart.couponcodes.join(",")
                    , 'purchase': //GA3
                        { 'actionField':
                          { 'id': orderinfo.orderid // Transaction ID. Required for purchases and refunds.
                       //   'affiliation': 'Online Store',
                          , 'revenue': paymentdata.ordertotal     // Total transaction value (incl. tax and shipping)
                       //   'tax':'4.90',
                          , 'shipping': paymentdata.shippingcosts
                          , 'shippingmethod': paymentdata.cart.shippingmethod
                          , 'paymentmethod': paymentdata.cart.paymentmethod
                          , 'paymentcosts': paymentdata.paymentcosts
                          , 'coupon': paymentdata.cart.couponcodes.join(",")
                          }
                        , products
                      }
                    }
                  });
  }
}

function trackProductClicks(event)
{
  let link = event.target.closest('a[href]');
  let productblock = event.target.closest('.webshop-products__item');
  if(!(link && productblock))
    return;

  if(productblock.webshopImpressionItem) //GA4
  {
    let imp = productblock.webshopImpressionItem;
    let ecommerce = { ...ecommercebase
                    , items: [imp]
                    };
    //shouldn't require a delay as GA4 uses beacons
    gtm.sendEvent("select_item", { ecommerce });
  }

  if(productblock.webshopImpressionInfo) //GA3
  {
    let imp = productblock.webshopImpressionInfo;
    dompack.stop(event);
    let ecommerce = { ...ecommercebase
                    , click: { list: imp.list
                             , products: [ imp ]
                             }
                    };
    gtm.sendEvent("productClick", { ecommerce }).then(() => location.href = link.href);
  }
}

function handleAddProduct(evt)
{
  gtm.sendEvent('addToCart', { ecommerce: { ...ecommercebase //GA3
                                          , add:
                                            { products: [ productInfoFromEvent(evt.detail) ]
                                            }
                                          }});

  gtm.sendEvent('add_to_cart', { ecommerce: { ...ecommercebase //GA4
                                            , items: [ productInfoFromEvent4(evt.detail) ]
                                            }});
}

function handleUpdateAmount(evt)
{
  let mutation = evt.detail.newamount - evt.detail.oldamount;

  { //GA3
    let ecommerce = { ...ecommercebase };
    let product = productInfoFromEvent(evt.detail);

    product.quantity = Math.abs(mutation);
    ecommerce[mutation < 0 ? "remove" : "add"] = { products: [product] };
    gtm.sendEvent(mutation < 0 ? 'removeFromCart' : 'addToCart', { ecommerce });
  }

  //GA4
  let product = { ...productInfoFromEvent4(evt.detail)
                , quantity: Math.abs(mutation)
                };

  gtm.sendEvent(mutation < 0 ? 'remove_from_cart' : 'add_to_cart',
    { ecommerce: { ...ecommercebase
                 , items: [ product ]
                 }
    });
}

export default function setupDatalayer(options)
{
  gtmoptions = { productclicks: true
               , maximpressions: 25
               , ...options
               };
  if(setupDatalayer.invoked)
    throw new Error(`Duplicate setupDatalayer call`);

  setupDatalayer.invoked = true;
  dompack.onDomReady(initGTM);
  if(gtmoptions.productclicks)
    addEventListener("click", trackProductClicks, { capture: true });

  window.addEventListener('webshop:productadded', handleAddProduct);
  window.addEventListener('webshop:updateamount', handleUpdateAmount);
}
