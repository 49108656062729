import * as finmath from '@mod-system/js/util/finmath';

function applyDiscounts(webshop, { product, amount, price, discounts, cartproductids, producttotal, filter, keepinactive = null })
{
  let promotions = [];
  const originalprice = price;

  let promotionprice = price;
  let bestpromotion = null;

  for (const discount of discounts)
  {
    if (!filter(discount))
      continue;

    let isactive = testCondition(discount.conditions, { amount, producttotal });

    if (isactive && discount.limited && discount.products) // per-product promotion discounts don't have product lists
    {
      if (!product || !discount.products.includes(product))
        isactive = false;
    }

    if (isactive && discount.hasrequiredproducts)
    {
      // at least one required product should be ordered
      if (!cartproductids.some(id => discount.requiredproducts.includes(id)))
        isactive = false;
    }

    const discountprice = finmath.subtract(originalprice, calculateDiscount(webshop, originalprice, discount.discount));

    // A 0-worth discount (eg free tv or so) is the best if there is no other discount active
    const isnewbest = isactive && (bestpromotion
        ? finmath.cmp(discountprice, promotionprice) < 0
        : finmath.cmp(discountprice, promotionprice) <= 0);

    const promotionrec = { ...discount, value: finmath.multiply(discount.limited ? amount : 1, finmath.subtract(originalprice, discountprice)), active: isnewbest };

    if (!isactive && (!keepinactive || !keepinactive(promotionrec)))
      continue;

    promotions.push(promotionrec);
    if (isnewbest)
    {
      if (bestpromotion)
        bestpromotion.active = false;
      bestpromotion = promotionrec;
      promotionprice = discountprice;
    }
  }

  // active last, then sort on increasing value
  promotions.sort((a, b) => a.active ? 1 : b.active ? -1 : finmath.cmp(a.value, b.value));

  return { promotionprice, promotions };
}


export function applyProductQuantityDiscounts(webshop, { product = 0, amount, price, discounts })
{
  let res = applyDiscounts(webshop,
      { product
      , amount
      , price
      , discounts
      , cartproductids: []
      , producttotal: 0
      , filter: discount => discount.type === "quantity"
      });

  return res.promotionprice;
}

export function applyProductPromotionDiscounts(webshop, { product, amount, price, discounts, cartproductids, producttotal, couponcodes = [] })
{
  return applyDiscounts(webshop,
      { product
      , amount
      , price
      , discounts
      , cartproductids
      , producttotal
      , filter: discount =>
        {
          if (!discount.limited)
            return false;
          if (discount.type === "promotion")
            return true;
          if (discount.type === "singleusecode" || discount.type === "multiusecode")
            return couponcodes.includes(discount.code);
          return false;
        }
      });
}

export function applyOrderDiscounts(webshop, { ordertotal, discounts, cartproductids, producttotal, couponcodes = [] })
{
  return applyDiscounts(webshop,
      { product: 0
      , amount: 1
      , price: ordertotal
      , discounts
      , cartproductids
      , producttotal
      , filter: discount =>
        {
          if (discount.limited)
            return false;
          if (discount.type === "promotion")
            return true;
          if (discount.type === "singleusecode" || discount.type === "multiusecode")
            return couponcodes.includes(discount.code);
          return false;
        }
      , keepinactive: discount => discount.type === "singleusecode" || discount.type === "multiusecode"
      });
}

function testCondition({ minitemcount = 0, minproducttotal = 0 }, { amount, producttotal })
{
  return amount >= minitemcount && producttotal >= minproducttotal;
}

function calculateDiscount(webshop, price, discount)
{
  let percentage = (discount.percentage && finmath.cmp(discount.percentage, 0) !== 0 && discount.percentage) || 0;
  let fixed = (discount.fixed && finmath.cmp(discount.fixed, 0) !== 0 && discount.fixed) || 0;

  let reduction = finmath.add(finmath.getPercentageOfAmount(price, percentage), fixed);

  // If roundpricetowhole is TRUE, round the price after discount down to whole numbers
  if (discount.roundpricetowhole)
    reduction = finmath.subtract(price, finmath.roundToMultiple(finmath.subtract(price, reduction), 1, "down"));
  else
    reduction = webshop.roundMoney(reduction, "up");

//  if( (price < 0 AND reduction > 0) or (price > 0 AND reduction < 0))
//    THROW NEW Exception("Discount not understood");

  // Don't discount below 0
  if (finmath.cmp(finmath.subtract(price, reduction), 0) < 0)
  {
    return finmath.cmp(0, price) < 0 // return max(0, price)
        ? price
        : "0";
  }

  return reduction;
}
