import * as cookie from 'dompack/extra/cookie';

export function setupFavorite(webshop, node)
{
  let productid;

  let productinfonode = node.closest("[data-webshop-product]");
  if(productinfonode)
    productid = JSON.parse(productinfonode.dataset.webshopProduct).id;

  if(!productid)
  {
    let productattrsnode = node.closest("[data-webshop-impression]"); //use in category overviews
    if(productattrsnode)
      productid = JSON.parse(productattrsnode.dataset.webshopImpression).dbid;
  }

  if(!productid)
  {
    console.error("Cannot locate product attributes for favlist icon", node);
    return;
  }

  let favlist = cookie.read("wh-webshop-favlist-" + node.value);
  if(favlist)
  {
    try
    {
      let favlistitems = JSON.parse(favlist);
      if(favlistitems.ids.includes(productid))
        node.checked = true;
    }
    catch(ignore)
    {
      //do not crash everything just because someone messed up their cookies
      cookie.remove("wh-webshop-favlist-" + node.value, { path: webshop.getCatalogPathname() });
    }
  }
  node.addEventListener("change", () => handleFavoriteChange(webshop, node, productid));
}

function handleFavoriteChange(webshop, node, productid)
{
  //If we get here, we should be able to assume the cookie list is readable
  let favlist = cookie.read("wh-webshop-favlist-" + node.value);
  let currentids = favlist ? JSON.parse(favlist).ids : [];
  let currentlyset = currentids.includes(productid);
  if(currentlyset == node.checked)
    return; //nothing to change!

  if(node.checked)
    currentids.push(productid);
  else
    currentids.splice(currentids.indexOf(productid),1);

  if(currentids.length)
    cookie.write("wh-webshop-favlist-" + node.value, JSON.stringify({ ids: currentids }), { path: webshop.getCatalogPathname() });
   else
    cookie.remove("wh-webshop-favlist-" + node.value, { path: webshop.getCatalogPathname() });
}
