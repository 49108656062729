/* webshop.testpricing suddenly started reporting
 ** UNEXPECTED EXCEPTION: SyntaxError: Error parsing /Users/arnold/projects/webhare/whtree/node_modules/dompack/package.json: Unexpected token , in JSON at position 29
   if we loaded just 'dompack' */

import * as dompack from 'dompack';

import * as finmath from '@mod-system/js/util/finmath';
import * as merge from 'dompack/extra/merge';
import { calculateProductPricesFromInfo, max_stock_amount } from './pricecalculation_calc';


function getImageOptionsIntersectionLength(optionlist, imageoptionsstr)
{
  if (!imageoptionsstr)
    return 0;
  const imageoptions = imageoptionsstr.split(" ").filter(v => v).map(nr => Number(nr));
  const retval = optionlist.filter(v => imageoptions.indexOf(v) !== -1).length;
  return retval;
}

export function constructStockInfoFromStockTiers(stocktiers)
{
  // Construct old-format stockinfo from stocktiers
  let stockinfo;
  if (stocktiers.length)
  {
    /* FIXME: the stockinfo can't handle cases where we have 2 products in
       stock, can order parts for 2 more, but 4 is max because a part isn't
       back-orderable anymore).
    */
    const lastelt = stocktiers[ stocktiers.length - 1 ];
    stockinfo =
        { requirestock: !lastelt.orderable
        , havestock: stocktiers[0].fromstock
        , free: stocktiers[0].fromstock && stocktiers[1].amountfrom ? stocktiers[1].amountfrom - 1 : 0
        };
  }

  return stockinfo;
}


/** Handler to update prices in a product lists based on selected optionvalues
    @long Update the data-webshop-select-optionvalues on node with class `.productsgrid` and fire
       a `webshop:optionsupdated` event on that node to update all the prices (via merge)
*/
export default class ProductListDynPriceHandler
{
  constructor(webshop,node)
  {
    this.webshop = webshop;
    this.node = node;

    this.node.addEventListener("webshop:optionsupdated", event => this._update());

    // Update prices only when webshopSelectOptionvalues has already been set
    if (this.node.dataset.webshopSelectOptionvalues || this.node.dataset.webshopCalcstock)
      this._update();
  }

  _update()
  {
    this._rpcUpdatePrices();
  }

  async _rpcUpdatePrices()
  {
    // Get the current list of optionvalues
    const optionvalues = (this.node.dataset.webshopSelectOptionvalues || "").split(" ").map(e => parseInt(e)).filter(e => e);
    const isbaseproduct = optionvalues.length === 0;

    const productnodes = dompack.qSA(this.node, ".webshop-products__item[data-webshop-dynpriceinfo]");
    const products = productnodes.map((node, idx) =>
    {
      const info = JSON.parse(node.dataset.webshopDynpriceinfo);
      return (
        { ref:            `${idx}`
        , product:        info.i
        , fixedoptions:   info.f || []
        });
    });

    if(!products.length)
      return;

    const res = await this.webshop._getFilterOptionValues(products, { optionvalues, allowoutofstock: this.node.dataset.webshopAllowoutofstock != "0" });
    for (const prod of res.products)
    {
      const node = productnodes[parseInt(prod.ref)];

      // Update product prices
      updateProductPrices(this.webshop, node, { amount: 1, options: prod.productvalues, isbaseproduct });

      // Update the stock info
      if (this.node.dataset.webshopCalcstock)
      {
        // Construct old-format stockinfo from stocktiers
        const stockinfo = constructStockInfoFromStockTiers(prod.stocktiers);
        publicizeStockInfo(this.webshop, node, stockinfo, prod.stocktiers);
      }

      dompack.dispatchCustomEvent(this.node, "webshop:internal-pricechange", { bubbles:true, cancelable:false, detail: { webshop: this.webshop }});
    }

  }
}

/** Calculates the hash parameters for options
    @param options List of productvalue id
    @param fixedoptions List of productvalue to skip
    @param hash URLSearchParams object
    @return The hash object will be updated
    @cell return.baselink Link without hash
*/
export function calculateProductHashParams(node, { options = [], fixedoptions = [], hash } = {})
{
  const info = JSON.parse(node.dataset.webshopDynpriceinfo);

  for (const productoption of info.o)
  {
    const productoptionid = productoption.p;
    const productoptiontag = productoption.pt;
    const productvalues = productoption.v;

    for (const productvalue of productvalues)
    {
      const productvalueid = productvalue.v;
      const productvaluetag = productvalue.vt;
      if (options.includes(productvalueid) && !fixedoptions.includes(productvalueid))
        hash.set('po_' + (productoptiontag || productoptionid), (productvaluetag || productvalueid));
    }
  }

  return { baselink: info.h };
}

/** Formats all prices from [calculateProductPricesFromInfo][#calculateProductPricesFromInfo] for merge.
*/
function formatPriceData(webshop, prices)
{
  const retval = {};
  for (const key in prices)
    retval[key] = key.endsWith("price") || key.endsWith("discount") || key.endsWith("inclvat") ? webshop.formatPrice(prices[key]) : prices[key];
  return retval;
}

/** Update the product prices below a product node
    @long Updates all prices, discount texts and links in a product node, based on the selected amount and options. Uses merge to
        update all merge fields. When a node with attribute `data-webshop-amountoverride` is encountered, the prices within that
        node will be calcuated with that amount
    @param webshop Webshop
    @param node Product node (must have a `data-webshop-dynpriceinfo` attribute)
    @param options
    @cell options.amount Amount
    @cell options.options Selected product value ids
    @cell options.fixedoptions Product value ids to keep out of the hash
    @cell options.oncalculateprice Callback to update the price with configuration-specific stuff. Signature: price func(price, hash)
    @cell options.optionmap Option map from productoption to productvalue, only needed for stock calculation
    @cell options.calcstock Whether to update stock info
    @return Updates the prices via merge nodes,
    @cell return.hash URLSearchParams filled with hash parameters for the current configuration
*/
export function updateProductPrices(webshop, node, { amount = 1, options = [], fixedoptions = [], oncalculateprice, optionmap = null, calcstock = false, isbaseproduct } = {})
{
  const info = JSON.parse(node.dataset.webshopDynpriceinfo);
  const hash = new URLSearchParams;
  const { baselink } = calculateProductHashParams(node, { options, hash });
  const prices = calculateProductPricesFromInfo(webshop, info, { amount, options, oncalculateprice, hash });
  const image = calculateBestImageFromInfo(info, { options });

  let link = baselink;
  if(hash.toString() && !isbaseproduct)
    link = `${link.split("#")[0]}#${hash}`;

  // set the current price
  node.dataset.webshopCurrentprice = prices.price;

  merge.run(node,
      { ...formatPriceData(webshop, prices)
      , link
      , ...image
      },
      { filter: mergenode => !mergenode.closest("*[data-webshop-amountoverride]")
      });

  let baseprices = null;

  dompack.qSA(node, "*[data-webshop-amountoverride]").forEach(subnode => // '1 voor zoveel, 2 voor zoveel'
  {
    if (!baseprices)
      baseprices = calculateProductPricesFromInfo(webshop, info, { amount: 1, options });

    const subamount = parseInt(subnode.dataset.webshopAmountoverride) || 1;
    //quantity prices
    const qprices = calculateProductPricesFromInfo(webshop, info, { amount: subamount, options, withexquantityprice: true });
    const mergeqprices =
        { price:                  qprices.price
        , priceinclvat:           qprices.priceinclvat
        , discount:               finmath.subtract(qprices.exqprice, qprices.price)
        , discountinclvat:        finmath.subtract(qprices.exqpriceinclvat, qprices.priceinclvat)
        , totalprice:             qprices.totalprice
        , totalpriceinclvat:      qprices.totalpriceinclvat
        , totaldiscount:          finmath.subtract(qprices.totalexqprice, qprices.totalprice)
        , totaldiscountinclvat:   finmath.subtract(qprices.totalexqpriceinclvat, qprices.totalpriceinclvat)
        };

    merge.run(subnode,
        { ...formatPriceData(webshop, mergeqprices)
        , link
        });
  });

  dompack.dispatchCustomEvent(node,"webshop:updatedprices", { bubbles: true
                                                            , cancelable: false
                                                            , detail: { webshop: webshop
                                                                      , currentprice: prices.price
                                                                      }
                                                            });
  return { hash };
}

/** Calculate prices for a product
    @param webshop Webshop
    @param node Product node, must have `webshop-dynpriceinfo` attribute
    @return @includecelldef #calculateProductPricesFromInfo.return
*/
export function calculateProductPrices(webshop, node, { amount = 1, options = [], oncalculateprice, hash, applydiscounts = true } = {})
{
  const info = JSON.parse(node.dataset.webshopDynpriceinfo);
  return calculateProductPricesFromInfo(webshop, info, { amount, options, oncalculateprice, hash, applydiscounts });
}


export function getBestImage(images, selectedoptions)
{
  let best = null, bestmatches = 0;
  for (let m of images)
  {
    const matches = getImageOptionsIntersectionLength(selectedoptions, m.p);
    if (!best || matches > bestmatches)
    {
      best = m;
      bestmatches = matches;
    }
  }
  return best;
}

export function calculateBestImageFromInfo(info, { options = [] } = {})
{
  let best = getBestImage(info.m, options);
  return best &&
      { image: best.l
                  ? { link: best.l, title: best.t, width: best.w, height: best.h, id: best.i, idx: best.n }
                  : { id: best.i, idx: best.n }
      };
}

/** Returns all discounts applicable to this product or that this product is required for
*/
export function getRelevantDiscountsForProduct(webshop, productid)
{
  const metainfo = webshop._getExtraData();
  const productdiscounts = metainfo.discounts;

  return productdiscounts.filter(discount =>
      discount.limited
          ? discount.products.includes(productid)
          : !discount.hasrequiredproducts || discount.requiredproducts.includes(productid));
}

export function publicizeStockInfo(webshop, node, stockinfo, stocktiers)
{
  //--isoutofstock is specified to be true ONLY if you cannot order when out of stock.
  node.classList.toggle("webshop--isoutofstock", stockinfo && stockinfo.requirestock && !stockinfo.havestock);
  node.classList.toggle("webshop--isalwaysorderable", !stockinfo || !stockinfo.requirestock);
  if(stockinfo && stockinfo.free !== null && stockinfo.free < max_stock_amount)
    node.dataset.webshopInStock = String(stockinfo.free);
  else
  {
    if (stockinfo)
      stockinfo.free = null;
    node.removeAttribute("data-webshop-in-stock");
  }

  dompack.dispatchCustomEvent(node, "webshop:productstockinfo", { bubbles:true, cancelable:false, detail: { webshop: webshop, stockinfo, stocktiers } });
}

/*
price                     Basisprijs + opties - quantity - promotions
totalprice                :price * amount
listprice                 Listprice + opties
totallistprice            :listprice * amount
discounttext

quantityprice             price after quantity discounts
totalquantityprice        :quantityprice * amount
*/
