import './slideshow.scss';

import * as dompack from 'dompack';

import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';


import $ from 'jquery';

dompack.onDomReady(() => {
  $('.emb-slideshow').each(function() {
    let mySwiper = new Swiper($(this).find('.swiper-container'), {
      direction: 'horizontal',
      loop: true,
      autoplay: {
        delay: 3500,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });
  });
});
