import * as finmath from '@mod-system/js/util/finmath';

export function roundMoney(value, mode, decimals)
{
  let unit = 1;
  for (let i = 0; i < decimals; ++i)
    unit = finmath.multiply(unit, "0.1");
  return finmath.roundToMultiple(value, unit, mode);
}

/** Given a cost table, find the lowest applicable cost
  @param costtable
  @cell costtable.fromtotal
  @cell costtable.cost
  @param total
  @return The lowest cost from the costtable entries with fromtotal <= total
*/
export function getCostFromTable(costtable, total, decimals)
{
  if(!costtable.length) //costtable is empty?
    return "0"; //then simply assume no costs

  let bestcost = null;
  for(let row of costtable)
  {
    let rowcost = row.cost;
    if(row.percentage)
      rowcost = finmath.add(rowcost, finmath.getPercentageOfAmount(total, row.percentage));

    if(row.fromtotal)
    {
      let toconsider = finmath.add(total, rowcost); //add user-paid costs before considering the PM
      toconsider = roundMoney(toconsider, "half-toward-infinity", decimals);
      if(finmath.cmp(toconsider, row.fromtotal) < 0) //total not high enough to trigger this row
        continue;
    }

    if(bestcost === null || finmath.cmp(rowcost, bestcost) <= 0)//first or better offer
      bestcost = rowcost;
  }

  if(!bestcost)
    return null; //no cost rule is applicable? deny!

  return roundMoney(bestcost, "half-toward-infinity", decimals);
}
