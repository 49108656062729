import "./categorypage.scss";
import "./productpage.scss";

import * as dompack from 'dompack';

import $ from 'jquery';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-product'))
    return;

  $('.nav-link').click(function(evt) {
    evt.preventDefault();

    $('.nav-link').removeClass('active');
    $(this).addClass('active');

    let $tab = $('#' + $(this).data('tab'));
    $('.tab-pane').removeClass('show');
    $tab.addClass('show');
  })
});
