import $ from "jquery";
// import {Tab, Tooltip, Popover, Button, Dropdown, Util} from 'bootstrap/js/src';
// import * as popperjs from 'popper.js/dist/esm/popper.min.js';
import * as dompack from 'dompack';
import * as magnificpopup from 'magnific-popup';
import slick from 'slick-carousel';
// import * as sidebarjs from 'sidebarjs';

import './jquery.ddslick.js';
import './stickytabs.js';

//console.log("The bootstrap interface", Tab, Tooltip, Button, Dropdown, Util);

function initOptionDDSlick(select, reinit)
{
  if (reinit)
  {
    // ddslick active?
    const ddinput = select.previousSibling.closest(".dd-container");
    if (ddinput)
      $(ddinput).ddslick("destroy");
  }

  // Don't trigger for hidden options
  if (select.closest(".webshop-product__option--hidden"))
    return;

  $(select).ddslick({
      width: '100%',
      onSelected : function(data)
      {
        dompack.fireModifiedEvents(select);
      }
  });
}

$( document ).ready(function() {

    // // Enable popovers everywhere
    // $('[data-toggle="popover"]').popover({
    //     html: true,
    //     trigger: 'toggle',
    //     content: function() {
    //       // get the url for the full size img
    //       var url = $(this).data('image');
    //       return '<img src="' + url + '">'
    //     }
    //   });

    // // sidemenu
    // new sidebarjs.SidebarElement({
    //     component: document.querySelector('[sidebarjs="leftSidebar"]')
    // });

    // // toggle ul in sidemenu
    // $(".has-submenu ul").hide();
    // $(".has-submenu > a.submenu-toggle").click(function()
    // {
    //   $(this).next("ul").toggle().toggleClass('active');
    // });


    // init magnific popup in productpage
    var form = document.getElementById("product__images__preview__container");

    if (typeof (form) != 'undefined' && form !== null)
    {
        $('.product__images__grid, .product__images__preview').magnificPopup(
        {
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery:
            {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image:
            {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
            }
        });
    }

    /* init slick-carousel on product images
    $(".product__images__grid").slick({
     dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    // stickytabs productpage
    // $('#product__tabs .nav-tabs').stickyTabs();

    // ddslick selectmenu product options
    // $('.webshop-product__optionselect').each(function()
    // {
    //     var select = this;
    //     initOptionDDSlick(select);
    //     select.addEventListener("webshop:optionschanged", evt =>
    //     {
    //         initOptionDDSlick(select, true);
    //     });
    // });
    */
    // image preview product options
    $('a.dd-option').each(function() {
        var imgprev = $(this);
        if (imgprev.find('img').length > 0) {
            imgprev.addClass('image-preview');
            imgprev.attr('rel', $(imgprev.find('img')[0]).attr('src'));
        }
    });
    imagePreview();

});

function imagePreview()
{
    var xOffset = 10;
    var yOffset = 30;

    $('a.image-preview').hover(function(e)
    {
        this.t = this.title;
        this.title = '';
        var c = (this.t !== '') ? '<br>' + this.t : '';
        $('body').append('<div id="image-preview"><img src="'+ this.rel + '" alt="Image preview">' + c + '</div>');
        $('#image-preview')
            .css('top',(e.pageY - xOffset) + 'px')
            .css('left',(e.pageX + yOffset) + 'px')
            .fadeIn('fast');
    },
    function()
    {
        this.title = this.t;
        $('#image-preview').remove();
    });

    $('a.image-preview').mousemove(function(e)
    {
        $('#image-preview')
            .css('top', (e.pageY - xOffset) + 'px')
            .css('left', (e.pageX + yOffset) + 'px');
    });
}
