import './video.scss';

import * as dompack from 'dompack';

// dompack.onDomReady(() => {
//   if (!checkIsSafariAndLowerThanVersion15()) return;

//   const targets = dompack.qSA('.emb-video__video');

//   if (!targets.length) return;

//   window.addEventListener('resize', () => fixVideoHeight(targets));
//   fixVideoHeight(targets);
// });

// function fixVideoHeight(targets) {
//   for (const slider of targets) {
//     slider.style.width = 'auto';
//     slider.style.height = 'auto';

//     if (slider.offsetWidth > (slider.offsetHeight / 9) * 16) {
//       slider.style.height = (slider.offsetWidth / 16) * 9 + 'px';
//     } else {
//       slider.style.width = (slider.offsetHeight / 9) * 16 + 'px';
//       slider.style.height = slider.offsetHeight + 'px';
//     }
//   }
// }

// function checkIsSafariAndLowerThanVersion15() {
//   if (!navigator.userAgent.includes('Safari')) return false;

//   const regex = /version\/([\d.]+) safari\/[\d.]+/i;

//   const matches = navigator.userAgent.match(regex);
//   if (!matches) {
//     return;
//   }

//   return matches.length && parseInt(matches[1]) < 15;
// }
