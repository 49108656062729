import * as dompack from 'dompack';
import CartWidget from '@mod-webshop/js/widgets/listcart';


export default class CustomListCartWidget extends CartWidget {
  constructor(webshop, node, options) {
    super(webshop, node, options);
  }
  
  _generateProductRows(products)
  {
    return products.map( (node, idx) =>
        [ <tr class={{"webshop-listcart__productrow": true
                ,"webshop-listcart__productrow--hasimage": !!node.thumbnail
                ,"webshop-listcart__productrow--hasamountselect": node.amount > this.options.selectamountuntil
                ,"webshop-listcart__row": true
                }} data-webshop-cartrow={idx} data-webshop-carttype="product" data-webshop-linetotal={node.prediscountlinetotal}>
            <td class="webshop-listcart__imagecell">
            <span class="webshop-listcart__productimage">{node.thumbnail ? typeof node.thumbnail == "string" ? <img src={node.thumbnail} alt={node.title} /> : <img src={node.thumbnail.link} alt={node.title} /> : null}</span>
            </td>
            <td class="webshop-listcart__details">
              <a class="webshop-listcart__title" href={node.link}>{node.title}</a>
              {this._describeOptions(node)}
              { this.options.controls
                ? <div class="webshop-listcart__productamountcell">
                  {this._getAmountNode(node.amount)}
                  <span class="webshop-listcart__delete"></span>
              </div>
              : <div class="webshop-listcart__productamountcell">
                <span class="webshop-listcart__textamount">
                  {node.amount}
                </span>
              </div>
              }
            </td>
            <td class="webshop-listcart__linetotalcell">
            <span class="webshop-listcart__linetotal">{node.priceonrequest ? getTid("webshop:frontend.cart.priceonrequest") : this.webshop.formatPrice(node.prediscountlinetotal)}</span>
            </td>
            </tr>
            , ...(this.options.inlinediscounts ? this._generateDiscountRows(node.discounts) : [])
        ]);
  }

  _generateSimpleRow(type, title, linetotal)
  {
    let row;
    if(this.options.widerows.includes(type))
    {
      row = <tr class="webshop-listcart__totalrow" data-webshop-linetotal={linetotal}>
              <td class="webshop-listcart__totalcell" colspan={this.options.controls ? 5 : 4}>
                <span class="webshop-listcart__totallabel">{title}</span>
                <span class="webshop-listcart__total">{this.webshop.formatPrice(linetotal)}</span>
              </td>
            </tr>;
    }
    else
    {
      row = <tr class="webshop-listcart__row" data-webshop-linetotal={linetotal}>
              <td class="webshop-listcart__imagecell">
              </td>
              <td class="webshop-listcart__titlecell" colspan={this.options.controls ? 3 : 2}>
                <span class="webshop-listcart__title">{title}</span>
              </td>
              <td class="webshop-listcart__linetotalcell">
                <span class="webshop-listcart__linetotal">{this.webshop.formatPrice(linetotal)}</span>
              </td>
            </tr>;
    }
    row.classList.add("webshop-listcart__" + type + "row"); //eg "webshop-listcart__shippingrow"
    return row;
  }
}
  