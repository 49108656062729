import './products.scss';

// WebHare
import * as dompack from 'dompack';
import rpc from '../../shared/js/services.rpc.json';

// vendor
import $ from 'jquery';
window.$ = $;
import Masonry from 'masonry-layout';
require('../../shared/js/masonry-plus.js');

let productsGrid;
let allProducts = [];
let bestsellerSliderPos = 0;
let productSlider;
let masonrySettings = {};

dompack.onDomReady(() => {
  if (document.documentElement.classList.contains('page-products')) {
    initProductsPage();

    $('#diveworld-productsort').on('change', changeSorting);
    $('.filters--group__filter.closeable').each(function () {
      $($(this).find('.togglelist').get(0)).on('click', function () {
        if ($($($(this).parent()).parent()).hasClass('list-opened')) {
          $(this).removeClass('fa-angle-up');
          $(this).addClass('fa-angle-down');
        } else {
          $(this).removeClass('fa-angle-down');
          $(this).addClass('fa-angle-up');
        }

        $($($(this).parent()).parent()).toggleClass('list-opened');
      });
    });

    $('#toggle-mobile-filters').on('click', function () {
      $('#filters').toggleClass('opened');
    });

    $('#reset_filters').on('click', resetFilter);

    $('main').on('click', function (e) {
      if ($('#filters').hasClass('opened') && e.pageX > 240) {
        $('#filters').toggleClass('opened');
      }
    });
  }
});

function initProductsPage() {
  $('.js-collapse-filter').on('click', function (e) {
    e.preventDefault();
    $('html').toggleClass('filters-open');
  });

  // collect all products
  $('.p-product').each(function () {
    allProducts.push({
      id: parseInt($(this).data('id')),
      price: $(this).data('price'),
      name: $(this).data('name'),
      showfor: $(this).data('showforfilters').toString().split('.'),
    });
  });

  initProductsGrid();
}

function initProductsGrid() {
  if ($('#products-grid').length < 1) return;

  if ($('.p-product').length > 0) $('.sorting, .filters').addClass('active');

  // initialize the grid
  masonrySettings = {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    transitionDuration: 500,
    gutter: 16,
  };

  productsGrid = new Masonry('#products-grid', masonrySettings);
  productsGrid.layout();

  let showGridTimeout = 250;

  let filter = { priceIds: [] };

  applyFilterToGrid(filter);

  // initialized, show the grid after a timeout
  setTimeout(() => {
    $('#products-grid').addClass('show');
  }, showGridTimeout);

  // setup onchange events
  $(
    'input[name="price-max"],input[name="price-min"], input[type="checkbox"]'
  ).on('change', onFilterChange);

  setProductCountsForFilter();
}

function removeUnusableFilters() {
  $('.filters--group__filter').each(function () {
    if (!$(this).hasClass('pricefilter')) {
      let filters = $(this).find('.filter_option').toArray();

      if (filters.every((f) => $(f).data('products') === 0))
        $(this).css('display', 'none');
    }
  });
}

function setProductCountsForFilter() {
  $('.filter_option').each(function () {
    var count = 0;
    let cur = $(this);

    allProducts.forEach(function (p) {
      if (p.showfor.includes(cur.val())) count++;
    });

    $(this).attr('data-products', count);

    if (count === 0) {
      $(this).parent().css('display', 'none');
    } else {
      var text = $(cur.parent().find('label')[0]).text();
      $(cur.parent().find('label')[0]).text(text + ' [' + count + ']');
    }
  });

  removeUnusableFilters();
}

function onFilterChange() {
  let filter = getFilter();
  applyFilterToGrid(filter);
}

function getFilter() {
  let priceIds = [];
  let filterIds = [];

  $('.filter_option').each(function () {
    let cur = $(this);

    var el = document.getElementById(cur.attr('id'));

    if (el.checked) {
      filterIds.push(cur.val());
    }
  });

  allProducts.forEach(function (product) {
    let min = $('#product_price--from').val();
    let max = $('#product_price--to').val();

    if (parseInt(product.price) <= max && parseInt(product.price) >= min) {
      if (filterIds.length > 0) {
        if (filterIds.every((c) => product.showfor.includes(c))) {
          priceIds.push(product.id);
        }
      } else priceIds.push(product.id);
    }
  });

  return {
    priceIds: priceIds,
  };
}

function resetFilter() {
  var el;
  $('.filter_option').each(function () {
    let cur = $(this);

    el = document.getElementById(cur.attr('id'));

    el.checked = false;
  });
  applyFilterToGrid(undefined);
}

async function applyFilterToGrid(filter) {
  let products = await rpc.FilterProducts(allProducts, filter);
  productsGrid.layout({ filterids: products });
}

async function applySortingToGrid(value) {
  const [type, dir] = value.split(':');

  if (type === 'price') {
    $('#products-grid > a')
      .sort(function (a, b) {
        if (dir === 'up')
          return parseFloat($(a).data('price')) > parseFloat($(b).data('price'))
            ? 1
            : -1;
        else
          return parseFloat($(a).data('price')) < parseFloat($(b).data('price'))
            ? 1
            : -1;
      })
      .appendTo('#products-grid');
  } else if (type === 'name') {
    $('#products-grid > a')
      .sort(function (a, b) {
        if (dir === 'up')
          return $(a).data('name').toLowerCase() >
            $(b).data('name').toLowerCase()
            ? 1
            : -1;
        else
          return $(a).data('name').toLowerCase() <
            $(b).data('name').toLowerCase()
            ? 1
            : -1;
      })
      .appendTo('#products-grid');
  }

  productsGrid = new Masonry('#products-grid', masonrySettings);
}

function changeSorting() {
  let value = $('#diveworld-productsort').val();
  applySortingToGrid(value);
}
