import * as dompack from 'dompack';
import * as finmath from '@mod-system/js/util/finmath';
import * as whintegration from '@mod-system/js/wh/integration';
import * as forms from '@mod-publisher/js/forms';
import * as merge from 'dompack/extra/merge';

import { __applyMethodStyling } from './checkout';

export default class ConfirmQuoteWidget extends forms.RPCFormBase
{
  constructor(webshop, node, options = {})
  {
    super(node);
    this.webshop = webshop;

    for(let node of dompack.qSA(this.node,`.webshop-checkout__paymentmethods .wh-form__fieldline`))
    {
      let radio = node.querySelector("input[type=radio]");
      if(!radio)
        continue;

      radio.addEventListener("change", () => this._onPaymentMethodChange());
      __applyMethodStyling(webshop, node, 'paymentmethod');
    }

    this._updateCosts();
  }

  _onPaymentMethodChange()
  {
    this._updateCosts();
  }

  _updateCosts()
  {
    let totalcosts = whintegration.config.obj["webshop:quoteinfo"].prepaymenttotal;
    let selectedmethod = this.node.querySelector(`input[name="paymentmethod.paymentmethod"]:checked`);
    if(selectedmethod && selectedmethod.dataset.webshopMethodCost)
      totalcosts = finmath.add(totalcosts, selectedmethod.dataset.webshopMethodCost);

    merge.run(document, { "webshop": { "ordertotal":       this.webshop.formatPrice(totalcosts)
                                     }
                        });

  }
}
