import * as dompack from 'dompack';
import { getTid } from "@mod-tollium/js/gettid";
import "@mod-webshop/js/internal/webshop.lang.json";
import * as finmath from '@mod-system/js/util/finmath';

export default class CartWidget
{
  constructor(webshop, node, options)
  {
    this.webshop=webshop;
    this.node=node;
    this.options = { totals:       this.node.hasAttribute("totals") ? (this.node.getAttribute("totals") || "products discount?").split(" ") : []
                   , controls:     this.node.hasAttribute("controls")
                   , shippingcost: this.node.hasAttribute("shippingcost")
                   , paymentcost:  this.node.hasAttribute("paymentcost")
                   , widerows:     (this.node.hasAttribute("widerows") ? this.node.getAttribute("widerows") : "producttotal discounttotal ordertotal").split(' ')
                   , inlinediscounts: false
                   , selectamountuntil: 10
                   , selectmaxamount: 99999
                   , ...options
                   };

    if(!webshop)
      throw new Error("Invalid webshop");
    if(!node)
      throw new Error("Invalid node");

    window.addEventListener("webshop:cartupdated", evt => this._updateCart() );
    this._updateCart();
    this.node.classList.add("webshop-listcart");
    this.node.addEventListener("click", evt => this._onCartClick(evt));
    this.node.addEventListener("change", evt => this._onCartChange(evt), true);
  }
  _getAmountNode(amount)
  {
    let holder = <span class="webshop-listcart__productamount"></span>;
    if(this.options.selectamountuntil && amount <= this.options.selectamountuntil)
    {
      let selectel = <select class="webshop-listcart__selectamount"></select>;
      holder.appendChild(selectel);

      for(let choice = 1; choice <= this.options.selectamountuntil; ++choice)
      {
        let optionnode = <option value={choice}>{choice}</option>;
        if(choice == amount)
          optionnode.selected = true;
        selectel.appendChild(optionnode);
      }
      selectel.appendChild(<option value={">" + this.options.selectamountuntil}>{getTid("webshop:frontend.cart.more")}</option>);
    }
    else
    {
      holder.appendChild(<input class="webshop-listcart__inputamount" value={amount} type="number" min="0" inputmode="numeric" />);
    }
    return holder;
  }
  _describeOptions(item)
  {
    let options = item.options.map(opt =>
      <div class="webshop-listcart__option">
        <span class="webshop-listcart__optionlabel">{opt.label}</span>
        <span class="webshop-listcart__optionselected">{opt.selected}</span>
      </div>);

    let extralines = (item.extralines || []).map(line =>
    {
      // If the line looks like 'field: value', render it as optionlabel: optionselected
      let cpos = line.indexOf(": ");
      if (cpos !== -1)
      {
        return <div class="webshop-listcart__extraline">
                 <span class="webshop-listcart__optionlabel">{line.substr(0, cpos)}</span>
                 <span class="webshop-listcart__optionselected">{line.substr(cpos + 2)}</span>
               </div>;
      }
      else
      {
        return <div class="webshop-listcart__extraline">
                 <span class="webshop-listcart__singleextraline">{line}</span>
               </div>;
      }
    });

    if(options.length == 0 && extralines.length == 0)
      return null;
    else
      return <div class="webshop-listcart__options">{options}{extralines}</div>;
  }

  _emitProductDiscountRow(node)
  {
    return <tr class={{"webshop-listcart__productdiscountrow": true
                      ,"webshop-listcart__row": true
                      ,"webshop-listcart__row--inactive": !node.active
                      ,"webshop-listcart__row--candelete": node.candelete
                      }}
               data-webshop-carttype={node.type}
               data-webshop-linetotal={node.linetotal}
               {...(node.couponcode ? {"data-webshop-couponcode": node.couponcode} : "")}>
             <td class="webshop-listcart__imagecell">
             </td>
             <td class="webshop-listcart__titlecell" colspan={this.options.controls ? 2 : 1}>
               <a class="webshop-listcart__title">{node.discount.wrd_title}: {node.product.title}</a>
             </td>
              { this.options.controls &&
                  <td class="webshop-listcart__deletecell">
                    { node.candelete && <span class="webshop-listcart__delete"></span> }
                  </td>
              }
             <td class="webshop-listcart__linetotalcell">
               <span class="webshop-listcart__linetotal">{this.webshop.formatPrice(node.linetotal)}</span>
             </td>
           </tr>;
  }

  _generateProductRows(products)
  {
    return products.map( (node,idx) =>
              [ <tr class={{"webshop-listcart__productrow": true
                           ,"webshop-listcart__productrow--hasimage": !!node.thumbnail
                           ,"webshop-listcart__productrow--hasamountselect": node.amount > this.options.selectamountuntil
                           ,"webshop-listcart__row": true
                           }} data-webshop-cartrow={idx} data-webshop-carttype="product" data-webshop-linetotal={node.prediscountlinetotal}>
                  <td class="webshop-listcart__imagecell">
                    <span class="webshop-listcart__productimage">{node.thumbnail ? typeof node.thumbnail == "string" ? <img src={node.thumbnail} /> : <img src={node.thumbnail.link} width={node.thumbnail.width} height={node.thumbnail.height} /> : null}</span>
                  </td>
                  <td class="webshop-listcart__titlecell">
                    <a class="webshop-listcart__title" href={node.link}>{node.title}</a>
                    {this._describeOptions(node)}
                  </td>
                  { this.options.controls
                      ? <td class="webshop-listcart__productamountcell">
                          {this._getAmountNode(node.amount)}
                        </td>
                      : <td class="webshop-listcart__productamountcell">
                          <span class="webshop-listcart__textamount">
                            {node.amount}
                          </span>
                        </td>
                  }
                  { this.options.controls &&
                      <td class="webshop-listcart__deletecell">
                        <span class="webshop-listcart__delete"></span>
                      </td>
                  }
                  <td class="webshop-listcart__linetotalcell">
                    <span class="webshop-listcart__linetotal">{node.priceonrequest ? getTid("webshop:frontend.cart.priceonrequest") : this.webshop.formatPrice(node.prediscountlinetotal)}</span>
                  </td>
                </tr>
              , ...(this.options.inlinediscounts ? this._generateDiscountRows(node.discounts) : [])
              ]);
  }

  _generateDiscountRows(productdiscounts)
  {
    return productdiscounts.map(node => this._emitProductDiscountRow(node));
  }

  _generateOrderDiscountRows(orderdiscounts)
  {
    return orderdiscounts.map( (node,idx) =>
                <tr class={{"webshop-listcart__orderdiscountrow": true
                           ,"webshop-listcart__row": true
                           ,"webshop-listcart__row--inactive": !node.active
                           ,"webshop-listcart__row--candelete": node.candelete
                          }}
                    data-webshop-carttype={node.type}
                    data-webshop-linetotal={node.linetotal}
                    {...(node.couponcode ? {"data-webshop-couponcode": node.couponcode} : "")} >
                  <td class="webshop-listcart__imagecell">
                  </td>
                  <td class="webshop-listcart__titlecell" colspan={this.options.controls ? 2 : 1}>
                    <span class="webshop-listcart__title">{node.title}</span>
                  </td>
                  { this.options.controls &&
                      <td class="webshop-listcart__deletecell">
                        { node.candelete && <span class="webshop-listcart__delete"></span> }
                      </td>
                  }
                  <td class="webshop-listcart__linetotalcell">
                    <span class="webshop-listcart__linetotal">{this.webshop.formatPrice(node.linetotal)}</span>
                  </td>
                </tr>);
  }

  _generateGiftCardRows(giftcards)
  {
    return giftcards.map( (node,idx) =>
                <tr class={{"webshop-listcart__giftcardrow": true
                           ,"webshop-listcart__row": true
                           ,"webshop-listcart__row--inactive": !node.active
                           ,"webshop-listcart__row--candelete": node.candelete
                          }}
                    data-webshop-carttype={node.type}
                    data-webshop-linetotal={node.linetotal}
                    {...(node.couponcode ? {"data-webshop-couponcode": node.couponcode} : "")} >
                  <td class="webshop-listcart__imagecell">
                  </td>
                  <td class="webshop-listcart__titlecell" colspan={this.options.controls ? 2 : 1}>
                    <span class="webshop-listcart__title">{node.title}</span>
                  </td>
                  { this.options.controls &&
                      <td class="webshop-listcart__deletecell">
                        { node.candelete && <span class="webshop-listcart__delete"></span> }
                      </td>
                  }
                  <td class="webshop-listcart__linetotalcell">
                    <span class="webshop-listcart__linetotal">{this.webshop.formatPrice(node.linetotal)}</span>
                  </td>
                </tr>);
  }

  _generateSimpleRow(type, title, linetotal)
  {
    let row;
    if(this.options.widerows.includes(type))
    {
      row = <tr class="webshop-listcart__totalrow" data-webshop-linetotal={linetotal}>
              <td class="webshop-listcart__totalcell" colspan={this.options.controls ? 5 : 4}>
                <span class="webshop-listcart__totallabel">{title}</span>
                <span class="webshop-listcart__total">{this.webshop.formatPrice(linetotal)}</span>
              </td>
            </tr>;
    }
    else
    {
      row = <tr class="webshop-listcart__row" data-webshop-linetotal={linetotal}>
              <td class="webshop-listcart__imagecell">
              </td>
              <td class="webshop-listcart__titlecell" colspan={this.options.controls ? 3 : 2}>
                <span class="webshop-listcart__title">{title}</span>
              </td>
              <td class="webshop-listcart__linetotalcell">
                <span class="webshop-listcart__linetotal">{this.webshop.formatPrice(linetotal)}</span>
              </td>
            </tr>;
    }
    row.classList.add("webshop-listcart__" + type + "row"); //eg "webshop-listcart__shippingrow"
    return row;
  }

  _generateSeparatorRow(type)
  {
    return <tr class="webshop-listcart__separatorrow">
             <td class="webshop-listcart__separatorcell" colspan={this.options.controls ? 5 : 4}>
             </td>
           </tr>;
  }

  _updateCart()
  {
    let cart = this.webshop.getCart();
    let checkoutinfo = this.webshop._checkoutinfo;
    let cartrows = [ ...this._generateProductRows(cart.products)
                   , ...(!this.options.inlinediscounts ? this._generateDiscountRows(cart.productdiscounts) : [])
                   , ...this._generateOrderDiscountRows(cart.orderdiscounts)
                   , ...this._generateOrderDiscountRows( (cart.customlines || []).map(_ => { _.active = true; return _; }))
                   ];

    let havetotal;
    if(this.options.totals.includes("products") && !cart.priceonrequest)
    {
      cartrows.push(this._generateSimpleRow("producttotal", getTid("webshop:frontend.cart.producttotal"), cart.preshippingtotal));
      havetotal = true;
    }

    if(this.options.totals.includes("discount") || (this.options.totals.includes("discount?") && finmath.cmp(cart.discounttotal, 0) !== 0))
    {
      cartrows.push(this._generateSimpleRow("discounttotal", getTid("webshop:frontend.cart.discounttotal"), cart.discounttotal));
      havetotal = true;
    }


    if(checkoutinfo)
    {
      if (havetotal)
        cartrows.push(this._generateSeparatorRow());
      cartrows.push(...this._generateGiftCardRows(checkoutinfo.giftcards));

      if(this.options.shippingcost && checkoutinfo.shipping)
        cartrows.push(this._generateSimpleRow("shipping", checkoutinfo.shipping.title, checkoutinfo.shipping.cost));

      if(this.options.paymentcost && checkoutinfo.payment)
        cartrows.push(this._generateSimpleRow("payment", checkoutinfo.payment.title, checkoutinfo.payment.cost));

      if(this.options.totals.includes("order"))
        cartrows.push(this._generateSimpleRow("ordertotal", getTid("webshop:frontend.cart.ordertotal"), checkoutinfo.ordertotal));
    }

    let newcartcontents =
      <div class={ {"webshop-listcart__products": true
                   ,"webshop-listcart__controls": this.options.controls
                   }} >
        <table class="webshop-listcart__productstable">
          <tbody>
            {cartrows}
          </tbody>
        </table>
      </div>;


    if(this.options.onNewCartDom)
      this.options.onNewCartDom(newcartcontents);

    dompack.empty(this.node);
    this.node.appendChild(newcartcontents);
    dompack.registerMissed(newcartcontents);
  }
  _onCartClick(evt)
  {
    let deletebutton = evt.target.closest(".webshop-listcart__delete");
    if(deletebutton)
    {
      dompack.stop(evt);

      let deleterow = deletebutton.closest(".webshop-listcart__row");
      if(deleterow.dataset.webshopCarttype == "product")
      {
        this._updateProductAmount(parseInt(deleterow.dataset.webshopCartrow), 0);
        return;
      }

      if(deleterow.dataset.webshopCouponcode)
      {
        let deletecode = deleterow.dataset.webshopCouponcode;
        this.webshop._cart.couponcodes = this.webshop._cart.couponcodes.filter(couponcode => couponcode != deletecode);
        this.webshop._cartUpdated({ preshippingchange: true });
        return;
      }

      if(deleterow.dataset.webshopCarttype == "loyaltypoints")
      {
        this.webshop._cart.useloyaltypoints = 0;
        this.webshop._cartUpdated({ preshippingchange: true });
        return;
      }

      throw new Error(`Don't know how to delete row of type '${deleterow.dataset.webshopCarttype}'`);
    }
  }

  async _onCartChange(evt)
  {
    let itemrow = evt.target.closest("[data-webshop-cartrow]");
    if(!itemrow)
      return;

    let newamount;
    if(evt.target.classList.contains("webshop-listcart__selectamount"))
    {
      if(evt.target.value.startsWith('>')) //this is the 'more than' option, replace the select with an input
        newamount = parseInt(evt.target.value.substr(1)) + 1;
      else
        newamount = parseInt(evt.target.value);
    }
    else if(evt.target.classList.contains("webshop-listcart__inputamount"))
    {
      newamount = parseInt(evt.target.value);
      if(! (newamount >= 1))
        return;
      if(newamount > this.options.selectmaxamount)
        newamount = this.options.selectmaxamount;
    }
    else
    {
      return; //change event not understood..
    }

    //do the update. don't need to chain await, noone's waiting for us anyway
    this._updateProductAmount(parseInt(itemrow.dataset.webshopCartrow), newamount);
  }
  async _updateProductAmount(row, newamount)
  {
    let lock = dompack.flagUIBusy();
    try
    {
      let products = [...this.webshop._cart.products]; //make a copy as we'll be proposing a chance, don't kill the cached version
      let productrow = products[row];
      let oldamount = productrow.amount;
      if(oldamount == newamount)
        return; //nothing changed

      if(newamount > 0)
        products[row].amount = newamount;
      else
        products.splice(row,1);

      //FIXME merge our update with checkout.es cart describer... we should have only one spot processing carts
      const proposedcart = { ...this.webshop._cart, products: products };
      let result = await this.webshop._describeCart(proposedcart); //FIXME handle any reported issues. share with _addToCart
      this.webshop._handleDescribeResult(result.issues, { async: false });
      this.webshop._cartUpdated({ productschange: true });

      let detail = { ...productrow, webshop: this.webshop, oldamount, newamount };
      detail.discount = finmath.subtract(detail.baseprice, detail.itemprice);

      dompack.dispatchCustomEvent(this.node, "webshop:updateamount", { bubbles:true, cancelable:false, detail });
    }
    finally
    {
      lock.release();
    }
  }
}
