import './sm-core-css.css';
import './sm-clean.css';

import './smartmenus.scss';

import $ from 'jquery';
require('./jquery.smartmenus.min.js');

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  $('#main-menu').smartmenus({
    mainMenuSubOffsetX: -1,
    mainMenuSubOffsetY: 4,
    subMenusSubOffsetX: 6,
    subMenusSubOffsetY: -6,
    // noMouseOver: true, // enable to allow inspecting/styling
  });
});
