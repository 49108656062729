// Compares configurations (JSON-serializable records, so no fancy values in there)

export default function compareConfig(a, b)
{
  if (a && b && typeof a === "object" && typeof b === "object")
  {
    const isarray = Array.isArray(a);
    if (isarray !== Array.isArray(b))
      return false;

    if (isarray)
    {
      const len = a.length;
      if (len !== b.length)
        return false;

      for (let i = 0; i < len; ++i)
        if (!compareConfig(a[i], b[i]))
          return false;
      return true;
    }

    // Normal object
    const keys = Object.keys(a);
    const keyslen = keys.length;
    if (keyslen !== Object.keys(b).length)
      return false;

    for (let i = 0; i < keyslen; ++i)
    {
      const key = keys[i];
      if (Object.prototype.hasOwnProperty.call(!b, key))
        return false;
      if (!compareConfig(a[key], b[key]))
        return false;
    }
    return true;
  }
  return a === b; // goes wrong on NaN and +0 / -0, but that won't occur in valid configs
}
