import './cart-and-checkout.scss';
import rpc from '../../shared/js/services.rpc.json';
import * as gtm from '@mod-publisher/js/analytics/gtm';
import * as whintegration from '@mod-system/js/wh/integration';

import * as dompack from 'dompack';

import $ from 'jquery';

dompack.onDomReady(() => {
  // setTimeout(() => {
  //   setImages();
  //   $(".webshop-listcart__delete").on("click", () => {
  //     setTimeout(setImages, 1000);
  //   });
  //   $(".webshop-listcart__selectamount").on("change", () => {
  //     setTimeout(setImages, 1000);
  //   });
  // }, 750);

  logCompletedPurchase();

  dompack.qS('#continueshopping')?.addEventListener('click', (e) => {
    e.preventDefault();
    if (window.history.length) {
      window.history.back();
      return;
    }
    window.location.assign(whintegration.config.obj.shoproot);
  });

  addEventListener("wh:form-pagechange", (evt) => {
    window.scrollTo({top: 0,
                     left: 0,
                     behavior: "smooth",});
  });
});

function logCompletedPurchase() {
  let purchasejsonstr = sessionStorage.getItem('purchasedata');
  let orderinfo = whintegration.config.obj['webshop:orderinfo'];
  sessionStorage.removeItem('purchasedata');

  let paymentdata = JSON.parse(purchasejsonstr);
  if (paymentdata) {
    let products = [];
    for (let i = 0; i < paymentdata.cart.products.length; ++i) {
      products.push({
        name: paymentdata.cart.products[i].title,
        sku: paymentdata.cart.products[i].sku,
        id: paymentdata.cart.products[i].sku,
        price: parseFloat(paymentdata.cart.products[i].itemprice),
        quantity: paymentdata.cart.products[i].amount,
      });
    }

    gtm.sendEvent('verkoop', {
      transactionId: orderinfo.orderid,
      transactionAffiliation: 'Webshop',
      transactionTotal: parseFloat(paymentdata.ordertotal),
      transactionProducts: products,
    });

    products = [];
    for (let i = 0; i < paymentdata.cart.products.length; ++i) {
      products.push({
        item_id: paymentdata.cart.products[i].sku,
        item_name: paymentdata.cart.products[i].title,
        affiliation: "Diveworld Webshop",
        // coupon: "optioneel SUMMER_FUN",
        item_brand: paymentdata.cart.products[i].brand,
        item_category: "Alle producten",
        item_category2: paymentdata.cart.products[i].categorypath[1],
        // item_variant: "optioneel green",
        price: parseFloat(paymentdata.cart.products[i].itemprice),
        quantity: paymentdata.cart.products[i].amount,
      });
    }

    // GA4
    gtm.sendEvent('purchase', {
      ecommerce: {
        transaction_id: orderinfo.orderid,
        value: parseFloat(paymentdata.ordertotal),
        shipping:  parseFloat(paymentdata.shippingcosts),
        currency: "EUR",
        // coupon: "",
        items: products,
      }
    });
  }

  // ecommerce
  if (paymentdata) {
    let products = [];
    for (let i = 0; i < paymentdata.cart.products.length; ++i) {
      products.push({
        item_id: paymentdata.cart.products[i].sku,
        item_name: paymentdata.cart.products[i].title,
        affiliation: "Diveworld Webshop",
        // coupon: "optioneel SUMMER_FUN",
        item_brand: paymentdata.cart.products[i].brand,
        item_category: "Alle producten",
        item_category2: paymentdata.cart.products[i].categorypath[1],
        // item_variant: "optioneel green",
        price: parseFloat(paymentdata.cart.products[i].itemprice),
        quantity: paymentdata.cart.products[i].amount,
      });
    }

    let jsonData = {
      event: "purchase",
      ecommerce: {
        transaction_id: orderinfo.orderid,
        value: parseFloat(paymentdata.ordertotal),
        shipping:  parseFloat(paymentdata.shippingcosts),
        currency: "EUR",
        // coupon: "",
        items: products,
      }
    }
    // console.log(paymentdata);

    // Append to head
    let jsonScript = document.createElement("script");
    jsonScript.setAttribute("type", "application/json");
    jsonScript.innerHTML = JSON.stringify(jsonData);
    let head = dompack.qS("head");
    head.append(jsonScript);
  }
}

// function setImages()
// {
//   $("a.webshop-listcart__title").each(function(){
//     let pathStr = $(this).attr("href").substring($(this).attr("href").indexOf("?"));
//     const URLParams = new URLSearchParams(pathStr);
//     const prodId = URLParams.get("id");
//     const option = URLParams.get("options");
//     changeImageLink(this, parseInt(prodId), parseInt(option.substring(1,6)));
//   });
// }

// async function changeImageLink(div, prodId, optionId)
// {
//   let imagelink = await rpc.GetProductImageForOption(prodId, optionId);

//   if(imagelink !== "oof" && imagelink !== undefined && imagelink !== "")
//   {
//     while($($(div).parent().parent().find("img")[0]).attr("src") !== imagelink)
//     {
//       $($(div).parent().parent().find("img")[0]).attr("src", imagelink);
//     }
//   }
// }
