import * as dompack from 'dompack';
import AutoSuggest from 'dompack/components/autosuggest';

export default class SearchWidget
{
  constructor(webshop, node, options)
  {
    this.webshop = webshop;
    this.node = node;

    let searchfield = dompack.qS(node,".webshop-search__searchfield");
    if(searchfield)
    {
      searchfield.autocomplete = "off";
      this._autosuggester = new AutoSuggest(searchfield, this, { minlength: 1 });
      searchfield.addEventListener("dompack:autosuggest-selected", () => this._onSuggestSelected() );
    }
  }

  _onSuggestSelected()
  {
    window.setTimeout( () => this.node.closest('form').submit(),1 );
  }

  async lookup(q)
  {
    let request = await fetch("/.webshop/suggest.shtml?w=" + encodeURIComponent(JSON.stringify(this.webshop._getRPCPointer())) + "&q=" + encodeURIComponent(q));
    let response = await request.json();
    return response.results;
  }
}
