import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';
import * as finmath from '@mod-system/js/util/finmath';
import * as whintegration from '@mod-system/js/wh/integration';
import * as merge from 'dompack/extra/merge';
import { getTid } from "@mod-tollium/js/gettid";
import * as forms from '@mod-publisher/js/forms';
import * as storage from 'dompack/extra/storage';
import WRDAuthenticationProvider from '@mod-wrd/js/auth';
import StableStringify from '../internal/stable-stringify';
import KeyboardHandler from "dompack/extra/keyboard";

function getClientIds()
{
  let clientids = [];
  if(!window.ga)
    return clientids;

  try
  {
    for(let tracker of window.ga.getAll())
    {
      let trackingId = tracker.get('trackingId');
      let clientId = tracker.get('clientId');
      if(!clientids.find(client => client.type == 'googleanalytics' && client.account == trackingId))
        clientids.push({type: 'googleanalytics', account: trackingId, clientid: clientId });
    }
    return clientids;
  }
  catch(e)
  {
    console.log(e);
    return clientids;
  }
}

function getShippingCostFromCostInfoTable(costtable, shippingcountry, shippingzipcode)
{
  if(!costtable || !costtable.length)
    return {cost:"0", vat:"0"};

  let bestmatch = null;
  for(let row of costtable)
    if(row.countries.length == 0 || row.countries.includes(shippingcountry)) //possible match
    {
      if (row.zipfilter.length)
      {
        const zip = Number(shippingzipcode.substr(0, 4));
        if (!zip || !row.zipfilter.find(r => r.startzip <= zip && r.endzip >= zip))
          continue;
      }

      if(!bestmatch || finmath.cmp(row.cost, bestmatch.cost) < 0)
        bestmatch = row;
    }

  return bestmatch ? bestmatch : null;
}

function getPaymentCostFromTable(costtable, total, decimals)
{
  if(!costtable || !costtable.length)
    return {cost:"0", vat:"0"};

  let bestmatch = null;
  for(let row of costtable)
  {
    let rowcost = row.cost;
    if(row.fromtotal)
    {
      let toconsider = finmath.add(total, rowcost); //add user-paid costs before considering the PM
      if(finmath.cmp(toconsider, row.fromtotal) < 0) //total not high enough to trigger this row
        continue;
    }

    if(!bestmatch || finmath.cmp(row.cost, bestmatch.cost) < 0)
      bestmatch = row;
  }

  return bestmatch ? bestmatch : null;
}

export function __applyMethodStyling(webshop, node, which)
{
  let radio = node.querySelector("input[type=radio]");
  let cost = radio.dataset.webshopMethodCost;

  node.classList.toggle(`webshop-checkout__${which}`, true);
  node.classList.toggle(`webshop-checkout--selected`, radio.checked);
  node.classList.toggle(`webshop-checkout--isfree`, cost === '0');
  let costsnode = node.querySelector(".webshop-checkout__cost");
  if(!costsnode)
  {
    costsnode = <span class="webshop-checkout__cost" />;
    node.appendChild(<span class="webshop-checkout__costs">{costsnode}</span>);
  }
  costsnode.textContent = cost ? webshop.formatPrice(cost) : "";
}

export default class CheckoutWidget extends forms.RPCFormBase
{
  constructor(webshop, node, options = {})
  {
    super(node);
    this.webshop = webshop;
    this.isquote = document.documentElement.classList.contains("webshop--isrequestquotepage");
    this.options = { ...options };

    if(!webshop)
      throw new Error("Invalid webshop");
    if(!node)
      throw new Error("Invalid node");

    //recover previous state
    this._restoreFormState();
    if(location.hash=='#page2')
      this.gotoPage(1);

    dompack.qSA(this.node, '.webshop-checkout__addcouponbutton').forEach(node => node.addEventListener("click", evt => this._doAddCode(evt)));
    dompack.qSA(this.node, '.webshop-checkout__addcouponcode').forEach(node => new KeyboardHandler(node, { "Enter": evt => this._onAddCodeEnter()}));
    dompack.qSA(this.node, '.webshop-checkout__addloyaltybutton').forEach(node => node.addEventListener("click", evt => this._doAddLoyalty(evt)));
    dompack.qSA(this.node, '.webshop-checkout__addcouponbutton').forEach(node => node.addEventListener("click", evt => this._doAddCode(evt)));
    dompack.qSA(this.node, '.webshop-checkout__triggerlogin').forEach(node => node.addEventListener("click", evt => this._tryTriggerLogin(evt)));

    //These catch existing situations for backwards compatibility until they are resolved. Do not use these classes, these will be removed eventually!
    dompack.qSA(this.node, '.hp-checkout__loginlink, .skw-checkout__loginlink, .ortho-checkout__loginlink').forEach(node => node.addEventListener("click", evt => this._tryTriggerLogin(evt), true));

    this.node.addEventListener('input', evt => this._saveFormState(evt), true);
    this.node.addEventListener('change', evt => this._onFormChange(evt), true);
    window.addEventListener("webshop:cartupdated", evt => this.onCartUpdated(evt));

    this._asyncConstructor();

    this.methodlist = ["shipping", "payment"];
    this.methods = { shipping: null
                   , payment:  null
                   };
  }
  async _asyncConstructor()
  {
    let lock = dompack.flagUIBusy();
    try
    {
      this._shippingmethodfixmode = 'initial'; //request shipping method to be fixed on _update
      this._forcerefresh = true;
      await this._refresh();
      this.node.hidden = false;

      let detail = { webshop: this.webshop };
      dompack.dispatchCustomEvent(this.node, 'webshop:cartready', { bubbles:true, cancelable:false, detail: detail });
    }
    finally
    {
      lock.release();
    }
  }

  async submit(extradata)
  {
    if(this.methods.payment && this.methods.payment.warnlive && !confirm(getTid("webshop:frontend.cart.livepayment")))
      throw new Error('Aborted when confirming live payment');

    return super.submit(extradata);
  }

  async _tryTriggerLogin(evt)
  {
    if(evt) //from a link
      dompack.stop(evt);

    //we're moving away from the radio button. this handler is there to not break existing shops while we mgirate
    if(this._isloginup || WRDAuthenticationProvider.getDefaultAuth().isLoggedIn())
      return;

    this._isloginup = true;
    //TODO can't wrd offer this dailog or the hooks to override it?
    let dialog = dialogapi.createDialog();
    dialog.contentnode.appendChild(document.getElementById("webshop-checkout-loginform").content.cloneNode(true));

    // Show the dialog. runModal returns a promise that will resolve to the dialog result
    await dialog.runModal();
    this._isloginup = false;
  }

  onCartUpdated(evt)
  {
    if(!evt.detail.productschange)
      return;

    this._refresh();
  }

  gotoPage(pageidx)
  {
    if(pageidx == 0)
      location.hash = '#';
    else if(pageidx == 1)
      location.hash = '#page2';

    super.gotoPage(pageidx);
  }

  _restoreFormState()
  {
    let inform = this.webshop._cart.checkoutform;
    for(var node of dompack.qSA(this.node,'*[name]'))
    {
      if(node.name == 'agree_terms')
        continue;
      if(node.type == 'radio' || node.type == 'checkbox')
      {
        if(node.value !== inform[node.name])
          continue;

        node.checked = true;
        dompack.fireModifiedEvents(node);
      }
      else
      {
        if(inform[node.name] !== undefined && node.value !== inform[node.name])
        {
          node.value = inform[node.name];
          dompack.fireModifiedEvents(node);
        }
      }
    }
  }

  isIgnoredField(field)
  {
    //do not save or trigger on changes made to fields that are not really part of our form submissions
    return field.name == "couponcode";
  }
  _saveFormState()
  {
    let val = {};
    //ADDME can't we replace this with the webhare form persist handling?
    for(var node of dompack.qSA(this.node,'*[name]'))
      if(!this.isIgnoredField(node) && node.name && ((node.type != 'radio' && node.type != 'checkbox') || node.checked))
        val[node.name]=node.value;

    this.webshop._updateCheckoutForm(val);
  }

  _onFormChange(evt)
  {
    if(this.isIgnoredField(evt.target))
      return;

    this._saveFormState();

    if (evt.target.matches('[name="separate_shipping_address"]'))
    {
      this._onCountryChange(evt);
      this._onZipChange(evt);
    }
    else if(evt.target.matches('[name="billing_address.country"],[name="shipping_address.country"],[name="shipping_country"]'))
      this._onCountryChange(evt);
    else if(evt.target.matches('[name="billing_address.zip"],[name="shipping_address.zip"],[name="shipping_zip"]'))
      this._onZipChange(evt);
    else
      this._refresh();
  }

  _getShippingCountryFieldName()
  {
    return this.elements.separate_shipping_address && this.elements.separate_shipping_address.checked ? "shipping_address.country" : "billing_address.country";
  }

  _getShippingZipFieldName()
  {
    return this.elements.separate_shipping_address && this.elements.separate_shipping_address.checked ? "shipping_address.zip" : "billing_address.zip";
  }

  _getShippingCountry()
  {
    return this.elements[this._getShippingCountryFieldName()].value;
  }

  _getCartChangedField()
  {
    if(!this.lastdescribe)
      return 'new';

    if(this.lastdescribe.lastcart != this.getDescribeRelevantCart())
    {
      // console.log(this.getDescribeRelevantCart());
      // console.log(this.lastdescribe.lastcart);
      return 'lastcart';
    }

    let lastcoupons = this.lastdescribe.describeresult.couponcodes.sort();
    let currentcoupons = this.webshop._cart.couponcodes.sort();
    if(JSON.stringify(lastcoupons) != JSON.stringify(currentcoupons))
      return 'coupons';

    let vatnr = this.elements.billing_vatnr ? this.elements.billing_vatnr.value.trim() : '';
    if(vatnr != this.lastdescribe.describeresult.billing_vatnr)
      return 'vatnr';

    if(vatnr && this.lastdescribe.shippingcountry != this._getShippingCountry())
      return 'shippingcountry';

    let curshippingcost = this._getCurrentShippingCosts();
    if(finmath.cmp(curshippingcost, this.lastshippingcost) != 0)
    {
      if(this.webshop.debug)
        console.log(`[sho] _getCartChangedField: shipping cost changed from ${this.lastshippingcost} to ${curshippingcost}`);

      return 'shippingcost';
    }

    return null;
  }

  _cartUpdateRequired()
  {
    let thechange = this._getCartChangedField();
    if(!thechange)
      return false;

    if(thechange == 'shippingcost'
       && !this.lastdescribe.describeresult.complexpaymentmethods
       && this.webshop._cart.giftcards.length == 0)
    {
      //We might be able to update the cost table clientside
      if(this.webshop.debug)
        console.log(`[sho] Field '${thechange}' changed but we can handle that clientside`);

      this._recalculatePaymentMethods();
      return false;
    }

    if(this.webshop.debug)
      console.log(`[sho] Need to update cart, field '${thechange}' changed`);

    return true;
  }

  //get the cart data that would be relevant for a describe call (refresh if this changes)
  getDescribeRelevantCart()
  {
    let cart = this.webshop._cart;

    return StableStringify({ /*codediscounts: cart.codediscounts || []
                           , */products: cart.products || []
                           , useloyaltypoints: cart.useloyaltypoints || 0
                           // , couponcodes: cart.couponcodes || []
                           });
  }

  /* We need to prevent this pattern:
     1.<change> 2.<refresh queued> 3.<change> 4.<refresh queud> 5.<change>...

     but it's important that only the intermediate refreshes are queued */

  async _refresh()
  {
    let lock = dompack.flagUIBusy();
    if(this.webshop.debug)
      console.log("[sho] refresh requested, force: " + this._forcerefresh + " pending: " + !!this.refreshpromise);
    if(this._forcerefresh)
    {
      this.issyncrefresh = true;
      this._forcerefresh = false;
    }

    if(this.refreshpromise) //we already promised to refresh
    {
      if(this.webshop.debug)
        console.log("[sho] preventing _refresh while one is pending");

      let result = await this.refreshpromise;
      lock.release();
      return result;
    }

    if(!this.issyncrefresh && !this._cartUpdateRequired(this.webshop._cart))
    {
      if(this.webshop.debug)
        console.log("[sho] decided we didn't need to refresh from the server");

      //we still need to apply the regular _describedcart updates
      this._recalculateShippingMethods();
      this._recalculatePaymentMethods();

      //just update
      this._update();
      lock.release();
      return;
    }

    let defer = dompack.createDeferred();
    this.refreshpromise = defer.promise; //used to block further callers

    let issues = [];
    try
    {
      for(let i=0;i<5;++i) //emergency brake after 5 refreshes...
      {
        const lastcart = this.getDescribeRelevantCart();
        let shippingcountry = this.elements[this._getShippingCountryFieldName()].value;
        let todescribe = { ...this.webshop._cart
                         , cache: undefined
                         , checkoutform: undefined
                         //we need to pass the country as the server doesn't know if we modified shipping_country field
                         //it can't really recognize whether shipping_country is actually in the form
                         , shippingcountry
                         };

        if(this.webshop.debug)
          console.log("[sho] requesting description for the following cart:", todescribe);

        let shippingmethodfixmode = this._shippingmethodfixmode;
        const describeresult = await this.invokeBackgroundRPC('describecart', todescribe);
        if(this.webshop.debug)
          console.log("[sho] description result: rpc", describeresult);

        this.lastdescribe = { describeresult, lastcart, shippingcountry };

        this.webshop._processDescribedCart(this.webshop._cart, describeresult);
        this._recalculateShippingMethods();
        this._recalculatePaymentMethods();
        this._update();

        issues = issues.concat(describeresult.issues);
        if(shippingmethodfixmode == 'initial') //we were updating because we wanted the new shipping methods
          issues = issues.filter(issue => issue.type != 'invalidshippingmethod'); //then ignore invalidshippingmethod for now

        if(!this._cartUpdateRequired(this.webshop._cart)) //we're stable!
        {
          defer.resolve(this.lastdescribe.describeresult);
          this.refreshpromise = null;

          if(describeresult.extra)
            dompack.dispatchCustomEvent(this.node, "webshop:describecart-extradata", { bubbles:true, cancelable:false, detail: describeresult.extra });

          this.webshop._handleDescribeResult(issues);

          this.issyncrefresh = false;
          lock.release();

          return defer.promise;
        }

        if(this.webshop.debug)
          console.log("[sho] cart not stable, retrying");

        //sleep 100 ms between calls
        await new Promise(resolve => setTimeout(resolve,100));
      }
      throw new Error("Unable to get stable cart result");
    }
    catch(e)
    {
      this.webshop.crashWebshop(e, 'Unable to describe the cart during checkout');
    }
  }

  _onCountryChange(evt)
  {
    let toset, setfrom;

    if(evt.target.name == 'shipping_country') //sync from preselect
    {
      toset = this.elements[this._getShippingCountryFieldName()];
      setfrom = evt.target;
      this._shippingmethodfixmode = 'initial';
    }
    else if(evt.target.name == 'separate_shipping_address' || evt.target.name == this._getShippingCountryFieldName())
    {
      toset = this.elements.shipping_country;
      setfrom = this.elements[this._getShippingCountryFieldName()];
      this._shippingmethodfixmode = 'warn';
    }

    if(this._shippingmethodfixmode) //an important country changed
    {
      if(toset && setfrom && toset.value != setfrom.value) //sync the fields
      {
        if(this.webshop.debug)
          console.log('[sho] new shipping country, was: %s, set: %s - source: %o, sync: %o', toset.value, setfrom.value, setfrom, toset);

        this.webshop._currentchangingfields.push("shippingcountry"); //suppress errors about the possiblecascading change
        dompack.changeValue(toset, setfrom.value);
      }
      //FIXME can't this decision go through _cartUpdateRequired ?
      this._recalculateShippingMethods();
    }
    this._refresh();
  }

  _onZipChange(evt)
  {
    let toset, setfrom;

    if(evt.target.name == 'shipping_zip') //sync from preselect
    {
      toset = this.elements[this._getShippingZipFieldName()];
      setfrom = evt.target;
      this._shippingmethodfixmode = 'initial';
    }
    else if(evt.target.name == 'separate_shipping_address' || evt.target.name == this._getShippingZipFieldName())
    {
      toset = this.elements.shipping_zip;
      setfrom = this.elements[this._getShippingZipFieldName()];
      this._shippingmethodfixmode = 'warn';
    }

    if(this._shippingmethodfixmode) //an important zip changed
    {
      if(toset && setfrom && toset.value != setfrom.value) //sync the fields
      {
        if(this.webshop.debug)
          console.log('[sho] new shipping zip, was: %s, set: %s - source: %o, sync: %o', toset.value, setfrom.value, setfrom, toset);

        this.webshop._currentchangingfields.push("shippingzip"); //suppress errors about the possiblecascading change
        dompack.changeValue(toset, setfrom.value);
      }
      //FIXME can't this decision go through _cartUpdateRequired ?
      this._recalculateShippingMethods();
    }
    this._refresh();
  }

  //recalculate the cost and availability for all shipping methods depending on the current shipping country
  _recalculateShippingMethods() //not sure where to place this yet
  {
    if(this.webshop._describedcart.shippingmethods.length == 0)
      return; //nothing to update (quote page?)

    let shippingcountry = this.elements[this._getShippingCountryFieldName()].value;
    let shippingzip = this.elements[this._getShippingZipFieldName()].value;
    for(let method of this.webshop._describedcart.shippingmethods)
    {
      let costinfo = getShippingCostFromCostInfoTable(method.costtable, shippingcountry, shippingzip);
      if(costinfo)
      {
        method.available = true;
        method.cost = costinfo.cost;
        method.vat = costinfo.vat;
      }
      else
      {
        method.available = false;
      }
    }
  }

  _recalculatePaymentMethods() //not sure where to place this yet
  {
    if(this.webshop._describedcart.paymentmethods.length == 0)
      return; //nothing to update (quote page?)

    let preshippingtotal = this.webshop._describedcart.preshippingtotal;
    let prepaymenttotal = finmath.add(preshippingtotal, this._getCurrentShippingCosts());

    for(let method of this.webshop._describedcart.paymentmethods)
    {
      let costinfo = getPaymentCostFromTable(method.costtable, prepaymenttotal);

      if(costinfo)
      {
        method.available = true;
        method.cost = costinfo.cost;
        method.vat = costinfo.vat;
      }
      else
      {
        method.available = false;
      }
    }
  }

  _update()
  {
    //Prepare totalprice (and respective vats) recalculation
    let inclvat = this.webshop._describedcart.pricesincludevat;
    this.priceonrequest = this.webshop.getCart().priceonrequest;

    let vatsofar = this.webshop._describedcart.preshippingvat || '0';
    let totalsofar = this.webshop._describedcart.preshippingtotal || '0';

    document.documentElement.classList.toggle("webshop--chargevats", this.webshop._describedcart.chargevats);
    document.documentElement.classList.toggle("webshop--priceonrequest", this.priceonrequest);

    //Update shiping methods. Also updates this.methods.shipping
    this._refreshMethods("shipping");
    if(this.methods.shipping)
    {
      totalsofar = finmath.add(totalsofar, this.methods.shipping.cost);
      vatsofar = finmath.add(vatsofar, this.methods.shipping.vat);
    }
    //record the shipping cost on which our current calculations dependend
    this.lastshippingcost = this.methods.shipping ? this.methods.shipping.cost : '0';

    //Apply any giftcards
    let giftcards = [];
    for(const giftcard of this.webshop._cart.giftcards)
    {
      let row = { ...giftcard, couponcode: giftcard.code, candelete: true, active: true};
      let cardvalue = giftcard.linetotal; //eg "-18"
      if(finmath.cmp(totalsofar, finmath.multiply("-1",cardvalue)) < 0) //if price < (-cardvalue), ie adding this card would drop price under zero
        row.linetotal = finmath.multiply("-1", totalsofar);

      totalsofar = finmath.add(totalsofar, row.linetotal);

      if (!row.title)
        row.title = getTid("webshop:frontend.cart.giftcard", row.code);

      giftcards.push(row);
    }

    //Do we need to pay ?
    let allowpayment = this.webshop._describedcart.paymentmethods.length > 0 && finmath.cmp(0, totalsofar) < 0; //there's a price to pay
    if(allowpayment)
    {
      this._refreshMethods("payment");
      if(this.methods.payment)
      {
        totalsofar = finmath.add(totalsofar, this.methods.payment.cost);
        vatsofar = finmath.add(vatsofar, this.methods.payment.vat);
      }
    }

    if(inclvat) //prices already have vat, so rem
    {
      this.ordertotal = totalsofar;
      this.ordertotal_exvat = finmath.subtract(totalsofar, vatsofar);
    }
    else
    {
      this.ordertotal_exvat = totalsofar;
      this.ordertotal = finmath.add(totalsofar, vatsofar);
    }

    if (this.elements["orderform-requesteddeliverydate"])
      this.elements["orderform-requesteddeliverydate"].min = this.webshop._describedcart.requesteddelivery_min.split("T")[0];

    this.webshop._setCheckoutInfo({ shipping: this.methods.shipping
                                  , payment: allowpayment ? this.methods.payment : null
                                  , ordertotal: this.ordertotal
                                  , ordertotal_exvat: this.ordertotal_exvat
                                  , giftcards: giftcards
                                  });
    this._refreshLoyaltyPoints();

    this.node.classList.toggle("webshop-checkout--cartisempty", this.webshop.isCartEmpty());

    merge.run(document, { "webshop": { "ordertotal":       this.webshop.formatPrice(this.ordertotal)
                                     , "ordertotal_exvat": this.webshop.formatPrice(this.ordertotal_exvat)
                                     }
                        });

    if(this.node.dataset.whFormVarRequirespayment != String(allowpayment))
    {
      this.node.dataset.whFormVarRequirespayment = String(allowpayment);
      this.refreshConditions();
    }

    // must be last, sends out update event
    let detail = { webshop: this.webshop
                 , ordertotal: this.ordertotal
                 , shippingmethod: this._getCurrentShippingMethod()?.title ||  ''
                 , paymentmethod: this._getCurrentPaymentMethod()?.title || ''
                 , shippingcost: this._getCurrentShippingCosts()
                 , paymentcost: this._getCurrentPaymentCosts()
                 };
    dompack.dispatchCustomEvent(this.node, "webshop:checkoutwidgetupdated", { bubbles:true, cancelable:false, detail: detail });
  }

  _getMethodCost(type, method)
  {
    if(!method)
      return null;

    if(type == "shipping")
    {
      let shippingcountry = this.elements[this._getShippingCountryFieldName()].value;
      let shippingzip = this.elements[this._getShippingZipFieldName()].value;
      return getShippingCostFromCostInfoTable(method.costtable, shippingcountry, shippingzip);
    }
    else
    {
      let preshippingtotal = this.webshop._describedcart.preshippingtotal;
      let prepaymenttotal = finmath.add(preshippingtotal, this._getCurrentShippingCosts());
      return getPaymentCostFromTable(method.costtable, prepaymenttotal);
    }
  }



  _refreshMethods(type)
  {
    let describedcart = this.webshop._describedcart;
    let which = type + 'method';
    let groupnode = dompack.qS(this.node, `.webshop-checkout__${which}s`);
    if(!groupnode)
      return; //this field is serverside hidden, so no need to udpate

    for(let node of dompack.qSA(groupnode,`.wh-form__fieldline`))
    {
      let radio = node.querySelector("input[type=radio]");
      if(!radio)
        continue;

      if(!radio.whPropWebshopHasOnChange)
      {
        radio.addEventListener("change", () => this._setMethod(type, null, true));
        radio.whPropWebshopHasOnChange = true;
      }

      let matchedmethod = describedcart[which+'s'].find(pm => pm.selectvalue == radio.value);
      let costinfo = this._getMethodCost(type, matchedmethod);
      if(costinfo)
      {
        radio.dataset.webshopMethodCost = costinfo.cost;
        node.dataset.webshopMethodCost = costinfo.cost;
      }
      else
      {
        radio.removeAttribute("data-webshop-method-cost");
        node.removeAttribute("data-webshop-method-cost");
        matchedmethod = null;
      }

      __applyMethodStyling(this.webshop, node, which);
      node.classList.toggle(`webshop-checkout--unavailable`, !matchedmethod);
      radio.disabled = !matchedmethod || node.closest('.wh-form__fieldline--hidden'); //bit of a hack that we're controlling disabled too... server should just send us all paymentmethod and disable/hide from there?
    }
    this._setMethod(type, false); //prevent races against browser - users clicking during cart load
  }

  _getCurrentShippingMethod()
  {
    let selectedmethod = dompack.qS(this.node, `input[name="shippingmethod.shippingmethod"]:checked:not(:disabled)`);
    if(selectedmethod && this.webshop._describedcart)
    {
      selectedmethod = this.webshop._describedcart.shippingmethods.find(pm => pm.selectvalue == selectedmethod.value);
      return selectedmethod;
    }
    else
      return null;
  }
  _getCurrentPaymentMethod()
  {
    let selectedmethod = dompack.qS(this.node, `input[name="paymentmethod.paymentmethod"]:checked:not(:disabled)`);
    if(selectedmethod && this.webshop._describedcart)
    {
      selectedmethod = this.webshop._describedcart.paymentmethods.find(pm => pm.selectvalue == selectedmethod.value);
      return selectedmethod;
    }
    else
      return null;
  }
  _getCurrentShippingCosts()
  {
    let shippingmethod = this._getCurrentShippingMethod();
    let costinfo = this._getMethodCost("shipping", shippingmethod);
    return costinfo ? costinfo.cost : "0";
  }
  _getCurrentPaymentCosts()
  {
    let costinfo = this._getMethodCost("payment", this._getCurrentPaymentMethod());
    return costinfo ? costinfo.cost : "0";
  }

  _setMethod(type, ischangeevent)
  {
    let selector = `input[name="${type}method.${type}method"]:checked`;
    if(type == 'shipping') //we can't check :disabled for payment methods, because shipping method changes triggering updates on paymentmethods race us.. maybe we shouldn't rely too much on form's visibility handling
      selector += ':not(:disabled)';
    let selectedmethod = dompack.qS(this.node, selector);

    if(type == 'shipping' && this._shippingmethodfixmode)
    {
      if(!selectedmethod) //must select first available
      {
        if(this._shippingmethodfixmode == 'initial')
        {
          selectedmethod = dompack.qS(this.node, `input[name="shippingmethod.shippingmethod"]:not(:disabled)`);
          if(selectedmethod)
            dompack.changeValue(selectedmethod,'true');
        }
        else if(!this.webshop._currentchangingfields.includes("shippingcountry") && !this.webshop._currentchangingfields.includes("shippingzip"))
        {
          this.webshop.reportStatus(getTid("webshop:frontend.checkout.invalidshippingmethod"),
                       { focusonclose: this.node.querySelector('[data-wh-form-group-for~="shippingmethod.shippingmethod"]') });
        }

      }
      this._shippingmethodfixmode = null;
    }

    let matchmethod;
    if(selectedmethod)
      matchmethod = this.webshop._describedcart[`${type}methods`].find(pm => pm.selectvalue == selectedmethod.value);

    if(matchmethod)
      this.methods[type] = matchmethod;
    else
      this.methods[type] = null;

    if(ischangeevent)
      this._refresh();
  }

  ///////////////////////////////////////////////////////
  //
  // Coupon codes
  //

  async _doAddCode(event)
  {
    if(event)
      dompack.stop(event);

    let codecontrol = this.node.querySelector('.webshop-checkout__addcouponcode');
    if(!codecontrol)
      throw new Error("No such element .webshop-checkout__addcouponcode");

    let code = codecontrol.value;
    if(!code)
    {
      this.webshop.reportStatus(getTid("webshop:frontend.checkout.discountcoderequired"), { focusonclose: codecontrol });
      return;
    }

    if(this.webshop._cart.couponcodes.includes(code.toUpperCase()))
    {
      this.webshop.reportStatus(getTid("webshop:frontend.checkout.discountcodealreadyadded", code), { focusonclose: codecontrol });
      return;
    }

    this.webshop._cart.couponcodes = [ ...(this.webshop._cart.couponcodes||[]), code.toUpperCase() ];
    this._forcerefresh = true;
    const describeresult = await this._refresh();
    if(!describeresult.issues.length)
    {
      codecontrol.value='';
      this._saveFormState();

      const checkoutnode = dompack.qS(".webshop-checkout webshop-cart div");
      if (checkoutnode)
        checkoutnode.scrollIntoView({ behavior: "smooth" });
    }
  }

  _onAddCodeEnter()
  {
    this._doAddCode();
    return true; //handled!
  }

  ///////////////////////////////////////////////////////
  //
  // Loyalty points
  //

  async _doAddLoyalty(event)
  {
    dompack.stop(event);

    if( (await this.validate([this.elements.loyaltypoints])).valid)
    {
      this.webshop._cart.useloyaltypoints = parseInt(this.elements.loyaltypoints.value);
      this._forcerefresh = true;
      this._refresh();
    }
  }

  _refreshLoyaltyPoints()
  {
    let loyalty = this.webshop._describedcart.loyalty;
    if(!this.elements.loyaltypoints || !loyalty)
      return; //this checkout form doesn't appear to support loyaltypoints

    let increment = Math.max(1, loyalty.spendincrement);
    //minimum can be safely 0 if it matches the increment size, otherewise enforce it and require manually typing/clearing ?
    let minimum = loyalty.spendminimum == increment ? 0 : loyalty.spendminimum;

    this.node.classList.toggle('webshop-checkout--offerloyaltypoints', loyalty.spendable > 0 && this.webshop._cart.useloyaltypoints == 0);
    this.elements.loyaltypoints.min =  minimum;
    this.elements.loyaltypoints.max =  loyalty.spendable;
    this.elements.loyaltypoints.step = increment;

    merge.run(document, { "webshop": { "loyaltypointsmax": loyalty.spendable
                                     }
                        });
  }

  async validateSingleFormField(field)
  {
    if(field.name == "loyaltypoints")
    {
      let error;
      let points = field.value ? parseInt(field.value) : 0;
      if(points)
      {
        if(points > 0 && points < parseInt(field.min))
          error = getTid("webshop:frontend.checkout.loyaltypointsbelowmin", field.min);
        else if(points > parseInt(field.max))
          error = getTid("webshop:frontend.checkout.loyaltypointsabovemax", field.max);
        else if( ((points - parseInt(field.min)) % parseInt(field.step)) != 0)
          error = getTid("webshop:frontend.checkout.loyaltypointsbadincrement", field.step);
      }

      forms.setFieldError(field, error);
      return !error;
    }
    return true;
  }

  async getFormExtraSubmitData()
  {
    await this._refresh(); //make sure all data is okay and this.ordertotal has been calculated

    //Gather clientids info (TODO generalize? should all forms offer this and let the server sort it out?)
    let clientids = getClientIds();

    //Gather GA4 client ids
    let idpromises = [];
    if(window.dataLayer && window.google_tag_manager)
      for(let ga4measurementid of Object.keys(window.google_tag_manager).filter(key => key.startsWith('G-')))
      {
        //for some reason we can't push it directly onto window.dataLayer but it has to go trough an arguments parameter
        let mygtag = function(){ window.dataLayer.push(arguments); };
        //ask the datalayer to get all their IDs
        idpromises.push(new Promise( resolve =>
        {
          mygtag('get', ga4measurementid, 'client_id', id =>
          {
            clientids.push({type: 'ga4', account: ga4measurementid, clientid: id });
            resolve();
          });
        }));
      }

    if(idpromises.length) //there is some stuff worth waiting for. wait up to 150ms to get tohse Ids
      await Promise.race([ Promise.all(idpromises)
                         , new Promise(resolve => setTimeout(resolve,150))
                         ]);
    return { cart: {...this.webshop._cart
                   , cache:null
                   } //remove the cache, the server already knows
           , ordertotal: this.ordertotal
           , clientids
           , referrerinfo: storage.getLocal("webshop.referrerinfo")
           };
  }

  onSubmitSuccess(result)
  {
    if(result.issues.length)
    {
      this.webshop._handleDescribeResult(result.issues);
      return;
    }

    if(result.submitinstruction)
    {
      let cart = this.webshop.getCart();
      sessionStorage.setItem("purchasedata", JSON.stringify({ cart: this.webshop._cart
                                                            , finalcart: cart //TODO switch all calculations over to this instead of reading internal _cart structures
                                                            , shippingcosts: cart.shipping ? cart.shipping.linetotal : "0"
                                                            , paymentcosts: cart.payment ? cart.payment.linetotal : "0"
                                                            , ordertotal: this.ordertotal
                                                            , discounttotal: cart.discounttotal
                                                            , submitinstruction: result.submitinstruction
                                                            }));
      whintegration.executeSubmitInstruction(result.submitinstruction);
    }
  }
}
