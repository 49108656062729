import * as discounts from '../support/discounts';
import * as finmath from '@mod-system/js/util/finmath';

// must be synced with frontend.whlib
export const max_stock_amount = 99999;

/** Calculate prices for a product
    @param webshop - Webshop
    @param info - Encoded pricing info @includecelldef mod::webshop/lib/internal/frontend.whlib#WebshopFrontend::__GetProductDynamicPriceInfo
    @cell options.withexquantityprice Add prices without quantity discounts
    @returns Prices
    @cell return.price Final price for a single product
    @cell return.totalprice Price for all products
    @cell return.listprice Final list price for a single product
    @cell return.totallistprice Final list price for all products
    @cell return.quantityprice Price for a single product after quantity discount, before promotions
    @cell return.totalquantityprice Price for all products after quantity discount, before promotions
    @cell return.prediscountprice Price for a single product before any discount
    @cell return.discounttext Applied promotion discount
    @cell return.exqprice Price without quantity discounts
    @cell return.totalexqprice Total price without quantity discounts
*/
export function calculateProductPricesFromInfo(webshop, info, { amount = 1, options = [], oncalculateprice, hash, withexquantityprice = false } = {}) {
  const product = info.i;
  const price = info.p || "0";
  const listprice = (info.l && info.l != "0") ? info.l : price;
  const addvatforincl = info.v;

  let new_price = price;
  let new_listprice = listprice;

  for (const productoption of info.o) {
    const productvalues = productoption.v;

    for (const productvalue of productvalues) {
      const extraprice = productvalue.e || "0";
      const extralistprice = productvalue.l || "0";
      const productvalueid = productvalue.v;

      if (options.includes(productvalueid)) {
        new_price = finmath.add(new_price, extraprice);
        new_listprice = finmath.add(new_listprice, extralistprice);
      }
    }
  }

  if (oncalculateprice) {
    new_price = oncalculateprice(new_price, hash || new URLSearchParams, amount);
    if (webshop.options.listprices)
      new_listprice = oncalculateprice(new_listprice, new URLSearchParams, amount);
  }

  if (new_price === "onrequest") {
    return {
      price: "onrequest",
      quantityprice: "onrequest",
      prediscountprice: "onrequest",
      totalprice: "onrequest",
      totalquantityprice: "onrequest",
      priceonrequest: true
    };
  }

  const metainfo = webshop._getExtraData();
  const productdiscounts = metainfo.discounts;

  let cartproductids = webshop._getCartProductIds();
  let producttotal = 0;


  const quantityprice = discounts.applyProductQuantityDiscounts(webshop, { product, amount, price: new_price, discounts: productdiscounts, cartproductids });

  const res = discounts.applyProductPromotionDiscounts(webshop, { product, amount, price: quantityprice, discounts: productdiscounts, cartproductids, producttotal });

  const activepromotion = res.promotions.find(p => p.active);

  let prices = {
    price: res.promotionprice,
    quantityprice: quantityprice,
    prediscountprice: new_price,
    totalprice: finmath.multiply(amount, res.promotionprice),
    totalquantityprice: finmath.multiply(amount, quantityprice),
  };

  if (webshop.options.listprices)
    prices = {
      ...prices
      , listprice: new_listprice
      , totallistprice: finmath.multiply(amount, new_listprice)
    };

  if (withexquantityprice) {
    const exqres = discounts.applyProductPromotionDiscounts(webshop, { product, amount, price: new_price, discounts: productdiscounts, cartproductids, producttotal });
    prices.exqprice = exqres.promotionprice;
    prices.totalexqprice = finmath.multiply(amount, exqres.promotionprice);
  }

  // Add '...inclvat' for all prices - but don't calculate (and round) vat for discount (difference between two prices)
  const retval = { ...prices };
  for (const entry of Object.entries(prices)) {
    retval[entry[0] + "inclvat"] = addvatforincl
      ? finmath.roundToMultiple(finmath.add(entry[1], finmath.getPercentageOfAmount(entry[1], addvatforincl)), "0.01", "half-toward-infinity")
      : entry[1];
  }

  // calculate discount amount and text
  if (webshop.options.listprices) {
    retval.discount = finmath.subtract(retval.listprice, retval.price);
    retval.discountinclvat = finmath.subtract(retval.listpriceinclvat, retval.priceinclvat);
  }
  retval.discounttext = activepromotion ? activepromotion.slogan || activepromotion.wrd_title : "";

  return { ...retval, priceonrequest: false };
}
