/* global Swiper */
import './slideshow.scss';

import * as dompack from 'dompack';
// import $ from 'jquery';


import $ from '@mod-mijnsiteonline/vendor/jquery-1.11.3/jquery-1.11.3.min.js';
// import $ from 'jquery';

// import Swiper from './swiper/swiper.min.js';
// import './swiper/swiper.min.css';
// const Swiper = require('./swiper/swiper.min.js');

dompack.onDomReady(() => {
  $('.mso-header-slideshow').each(function () {
    let $container = $(this).find('.swiper-container').first();

    let numSlides = $container.find('.swiper-slide').length;
    if (numSlides < 1) return;

    if (numSlides < 2)
      $container
        .find('.swiper-pagination, .swiper-button-prev, .swiper-button-next')
        .remove();
    else
      $container
        .find('.swiper-pagination, .swiper-button-prev, .swiper-button-next')
        .show();

    let speed = parseInt($(this).data('animationspeed'));
    if (!speed) speed = 500;

    let autoplay = parseInt($(this).data('autoplayspeed'));
    if (!autoplay) autoplay = 4000;

    let swiper = new Swiper($container, {
      loop: numSlides > 1,
      speed: speed,
      autoplay: autoplay,
      allowSwipeToPrev: numSlides > 1,
      allowSwipeToNext: numSlides > 1,
      grabCursor: numSlides > 1,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 0,
    });

    if ($(this).attr('data-pauseonhover')) {
      $container.hover(
        function () {
          this.swiper.stopAutoplay();
        },
        function () {
          this.swiper.startAutoplay();
        }
      );
    }
  });
});
